import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import './signin.css'
import logo from "../../Images/Logo.png"
import loginImage from '../../Images/loginImage.png'
import GoogleSignInButton from "../../components/GoogleSignInButton";
import errorEmailLogo from '../../Images/emailerror.png'
const Signin = () => {
  const [signInDetails, setSignInDetails] = useState({
    email: "",
    password: "",

  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

 // This effect checks if the email and password fields are filled, including autofill
 useEffect(() => {
  const allFieldsFilled = 
    signInDetails.email.trim() !== "" && 
    signInDetails.password.trim() !== "";

  setIsTermsAccepted(allFieldsFilled);
}, [signInDetails.email, signInDetails.password]); // Run when these values change

  // const passwordRegex = {
  //   minLength: /(?=.{6,})/,
  //   hasUppercase: /(?=.*[A-Z])/,
  //   hasNumber: /(?=.*\d)/,
  // };

  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  // });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false)
  const [loading, setLoading] = useState(false);  
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const router = useRoutes();
  // console.log(router, "router in login page");
  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  // });

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;

    setSignInDetails((prevSignInDetails) => ({
      ...prevSignInDetails,
      [name]: value,
    }));

    if(name === "email" && emailError){
      setEmailError("");
    }
    if(name === "password" && passwordError){
      setPasswordError("");
    }
     // Check if both fields are filled
    const allFieldsFilled = signInDetails.email !== "" && signInDetails.password !== "";
    setIsTermsAccepted(allFieldsFilled);
    // setIsTermsAccepted(false)
    setIsBtnDisabled(false)
  };

  const navigate = useNavigate();

  const handleSignin = async () => {
    setIsBtnDisabled(true);
    const payload = {
      email: signInDetails.email,
      password: signInDetails.password,
    };
    // console.log(payload);
    try {
      setLoading(true); 
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signin/",
        payload
      );
      console.log("response login page",response);

      if (response.data.message === "Sign-in successful.") {

        

        // setFirstName(response.data.first_name);
        // setLastName(response.data.last_name)
        // console.log("firstname", response.data.first_name);
        // console.log("lastname", response.data.last_name);
        
        

        localStorage.setItem("token", response.data.token);

         // If Remember Me is checked, store the email in localStorage
         localStorage.setItem("email", signInDetails.email);
         localStorage.setItem("firstName", response.data.first_name)
         localStorage.setItem("lastname", response.data.last_name)
        //  localStorage.setItem("first_name", firstName);
        //  localStorage.setItem("last_name", lastName);
        //  if (rememberMe) {
        //   localStorage.setItem("email", signInDetails.email);
        // } else {
        //   localStorage.removeItem("email"); // Remove email if Remember Me is unchecked
        // }

        toast.success("Sign-in successful", {
          autoClose: 2000,    
        });

        navigate("/ai-Copilot");
      } else if (response.data.message === "Invalid email.") {
        console.log("invalid email or password 1111111111");
          setEmailError("Email is not Found");
          setPasswordError("");  // Clear any existing password error
      }else if (response.data.message === "Invalid password for the provided email.") {
          console.log("password error in sign in")
          setPasswordError("Incorrect Password");
          setEmailError("");  // Clear any existing email error
      }
    } catch (error) {
      // Handle signup error
      toast.error("Signup failed. Please try again.");
      // console.error("Signup error:", error);
    }
    finally {
      setLoading(false); // Re-enable button after API call completes
    }
  };
  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  // const handleNavigateToForgotPassword = () => {
  //   navigate('/forgot-password');
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setVerified(true);
      navigate("/ai-copilot");
    }
    setIsBtnDisabled(false)
  }, []);


  useEffect(() => {
    // If "Remember me" was previously selected, pre-fill the email field
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      setSignInDetails((prevSignInDetails) => ({
        ...prevSignInDetails,
        email: rememberedEmail,
      }));
      setRememberMe(true); // Set the checkbox to true
    }
  }, []);
  // If verified is true, navigate to "/uploadFile"
  // useEffect(() => {
  //   if (verified) {
  //     navigate("/uploadFile");
  //   }
  // }, [verified, navigate]);
  return (
    <div className="bg-white ">
    <span className="main">
    <img className="logo-section"
        src={logo} 
        alt="docuSensa logo"  
    />
    </span>
    <div className="flex flex-wrap justify-center items-center">
      <section className="signup-section w-full md:w-1/2 flex flex-col justify-center">
        <div className="flex items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full md:mt-0 sm:max-w-md xl:p-0  bg-white ">
            <div className="md:space-y-6">
              <h2 
                className="font-bold get-started" 
                style={{
                  color: '#171717',
                  fontSize: '32px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                }}
              >
                Welcome back
              </h2>
            <p
              className="font-bold"
              style={{
                color: '#383838',
                
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: 'normal',
              }}
            >
              Enter your credentials to access your Account
            </p>
              <div className=" w-full">
                <GoogleSignInButton setVerified={setVerified} 
                loginMessage={loginMessage}
                />
              </div>

              <div className="or-style my-6">
                <hr className="flex-grow border-gray-300" />
                <span className="px-2 text-gray-500">or</span>
                <hr className="flex-grow border-gray-300" />
              </div>

              {/* <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                Sign in to your account
              </h1> */}
              
            {/* Email Field */}
              <div>
              <label
                  for="email"
                  className="block mb-2"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  value={signInDetails.email}
                  onChange={handleSignupDetailsChange}
                  className="p-6 w-[568px] h-[56px] rounded-2xl"
                  placeholder="enter your Email ID here"
                  required
                />
                 {emailError && (
                    <p className="text-red-500 text-sm mt-1 flex items-center">
                      <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> {emailError}
                    </p>
                  )}
              </div>

              {/* Password field */}
              <div>
              <label
                  htmlFor="password"
                  className="block mb-2"
                >
                  Enter Your password
                </label>
                <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={signInDetails.password}
                  onChange={handleSignupDetailsChange}
                  placeholder="min 8 chars"
                  className="p-6 w-[568px] h-[56px] rounded-2xl"
                  required
                />
                <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="show-pass absolute top-5 text-gray-500"
                    >
                      {showPassword ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} /> }
                </button>
                </div>
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1 flex items-center">
                    <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> {passwordError}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between mt-4 w-[568px]">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="remember"
                    className="remember text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <Link
                to="/request-password-reset"
                className="forgot-pass text-sm font-medium text-primary-600"
              >
                Forgot password?
              </Link>
              </div>

              {/* Login Button */}
              <button
                disabled={isBtnDisabled}
                onClick={handleSignin}
                type="submit"
                className={`signin-button ${
                  isTermsAccepted ? 'isTermAccepted' : 'notAccepted'
                } ${isBtnDisabled ? 'notAccepted' : 'isTermAccepted'}`}
              >
                Login
              </button>


              {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400"> */}
              <p className="login-text text-gray-700 ">
                Don’t have an account?{" "}
                <Link
                  to="/signup"
                  className="login-click  hover:underline"
                >
                  Signup Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Image Section */}
      <section 
      // className="image-section w-1/2 flex flex-col justify-center px-8 py-8 text-center">
      className="image-section hidden md:flex w-full md:w-1/2 justify-center items-center px-8 py-8 text-center">
      <div className="image-text">
        <div className="upper-text text-left">
          <h2 className="">Seamless AI Powered Document Processing</h2>
        </div>
        <div className="lower-text text-left">
          <h2>
            Streamline your workflow with advanced AI-driven<br/> tools designed to simplify document handling.
          </h2>
        </div>
      </div>
        
        {/* <div className="">
        <img src={loginImage} alt="signup promotion" className="signup-img w-1/2 object-cover" />
        </div> */}
              <img src={loginImage} alt="signup promotion" className="signup-img w-3/4 md:w-2/3 object-cover" />
      </section>
    </div>
      
    </div>
  );
};

export default Signin;

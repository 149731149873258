import React, { useState, useEffect } from 'react'
import Menu from '../chat/Menu'
import "./aicopilot.css"
import backup from "../../Images/backup.png"
import plus from "../../Images/plus1.png"
import send from "../../Images/send.png"
import bell from "../../Images/ai-bell.png"
import search from "../../Images/search-big.jpg"
import { Header } from '../../components/Header/Header'
import axios from "axios";
import { toast } from "react-toastify";
import uploadlogo from "../../Images/after-upload.png"
import blueai from "../../Images/blue-ai.png"
import findImage from "../../Images/find_in_page.png"
import find from "../../Images/find.jpg"
import { useSearchParams } from 'react-router-dom'
import { Documentupload } from './Documentupload'
import { useNavigate } from 'react-router-dom'
import { FileSuccessfullyUploaded } from './FileSuccessfullyUploaded'

const card = [
  {
    title: "What are the key things I should focus on this Contract"
  },
  {
    title: "Extract Dates - Identify all dates mentioned"
  },
  {
    title: "Find Keywords - Highlight key terms and phrases."
  },
  {
    title: "Verify Signatures - Check for all signatures"
  }
]


export const AIcopilot = ({ isMenuOpen}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [uploadFile, setUploadFiles] = useState(false);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState(null); 
  const [fileData, setFileData] = useState(null);
  const [contractKey, setContractKey] = useState("")


  useEffect(() => {
    const firstName = localStorage.getItem("firstName") || localStorage.getItem("first_name");
    const lastName = localStorage.getItem("lastname") || localStorage.getItem("last_name");
    const email = localStorage.getItem("email");
  
    setUser({
      firstName,
      lastName,
      email,
    });
  }, []);

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      // console.log("selectedFile", e.target.files[0]);
      
      const fileType = ["application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",];

    
      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          // toast.warn("Invalid file type", {
          //   autoClose: 2000,
          // });
          // reject(new Error("Invalid file type"));
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleResponse = (res, file) => {

    // console.log("res data is ---", res)
    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.doc_key) {
      console.log("PDF key received:", res.data.doc_key);
      localStorage.setItem("pdfKey", res.data.doc_key); 
    }
    

  // console.log(res.data.status, "data status is------")
    switch (res.data.status) {
      case "STARTING":
        console.log("STARTING: PDF key received:", res.data.doc_key);
        // localStorage.setItem("pdfKey", res.data.doc_key);
        // return setStatus("Uploading Pdf ..");
      case "LOADING":
        // return setStatus("Loading Pdf ...");
      case "ANALYZING":
        // return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        // return setStatus("Summarizing Pdf ...");
      case "PENDING":
        // return setStatus("Maybe Asynchronous task failed...");
      case "FAILED":
        // return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        // clearInterval(intervalId);  
        // setStatus("Upload successful!");
        // console.log(res.data, 'This is updated data')
        
         setStatus("Completed")
         setApiResponse(res); 
         setFileData(file);
        // navigate("/chat", {
        //       state: {
        //         summary: res.data.data.summary,
        //         questions: res.data.data.examples,
        //         file: file,
        //       },
        //     });
        break;
      default:
        console.error(`Unknown status from server ${res.data.status}`);
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  const handleSummarize = () => {
    if (status === "Completed" && apiResponse && apiResponse.data) {
      const { summary, examples } = apiResponse.data.data;
      navigate("/chat", {
        state: {
          summary: summary || "No summary available",
          questions: examples || [],
          file: fileData || {},
        },
      });
    } else {
      console.error("Summarize action cannot proceed. Missing data.");
      toast.error("Unable to proceed with summarization. Please try again.");
    }
  };
  
  
  useEffect(() => {
    console.log("Progress bar updated:", progress);
  }, [progress]);
  

  // const uploadDocument = async(e) =>{
  //   setUploadFiles(true);
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   // console.log("formData", formData);
  //   // console.log("e", e.target.files[0]);
  //   let file = null;
  //   handleFile(e)
  //     .then((res) => {
  //       file = res;
  //     })
  //     .catch((error) => {
  //       console.error("Error reading file:", error);
  //       return;
  //     });
  //   try {
  //     await axios.post(
  //       process.env.REACT_APP_API_URL + "/api/v1/copilot/qdrant-upload-doc/",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Token " + localStorage.getItem("token"),
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           console.log("Loaded:", progressEvent.loaded, "Total:", progressEvent.total);
  //           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //           setProgress(percentCompleted);
  //         }
          
  //       } 
  //     )
  //     .then((res) => {
  //       console.log('API Response:', res); 
  //       handleResponse(res, file);
  //       // intervalId = setInterval(() => getPdfStatus(file), 1000);
  //     });
  //   } catch (error) {
      
  //   }
  // }
  const uploadDocument = async (e) => {
    setUploadFiles(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    let file = null;
    handleFile(e)
      .then((res) => {
        file = res;
      })
      .catch((error) => {
        console.error('Error reading file:', error);
        return;
      });
    setProgress(0);
    const incrementProgress = (target, intervalSpeed) => {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress < target) {
              return prevProgress + 1;
            } else {
              clearInterval(intervalId);
              resolve();
              return prevProgress;
            }
          });
        }, intervalSpeed);
      });
    };
    await incrementProgress(30, 100);
    await incrementProgress(50, 100);
    await incrementProgress(80, 100);
    await incrementProgress(99, 100); 
    try {
     const res =  await axios
        .post(process.env.REACT_APP_API_URL + '/api/v1/copilot/qdrant-upload-doc/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Token ' + localStorage.getItem('token')
          },
          onUploadProgress: (progressEvent) => {
            const actualProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (actualProgress < 90) {
              setProgress(actualProgress);
            }
          }
        })
        setProgress(100);
        console.log(res?.data?.doc_key)
        setContractKey(res?.data?.doc_key)
      handleResponse(res, file);
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };
  // const uploadDocument = async (e) => {
  //   setUploadFiles(true);
  //   const formData = new FormData();
  //   formData.append('file', e.target.files[0]);
  //   let file = null;
  //   handleFile(e)
  //     .then((res) => {
  //       file = res;
  //     })
  //     .catch((error) => {
  //       console.error('Error reading file:', error);
  //       return;
  //     });
  //   setProgress(0);
  //   const incrementProgress = (target, intervalSpeed) => {
  //     return new Promise((resolve) => {
  //       const intervalId = setInterval(() => {
  //         setProgress((prevProgress) => {
  //           if (prevProgress < target) {
  //             return prevProgress + 1;
  //           } else {
  //             clearInterval(intervalId);
  //             resolve();
  //             return prevProgress;
  //           }
  //         });
  //       }, intervalSpeed);
  //     });
  //   };
  //   await incrementProgress(30, 100);
  //   await incrementProgress(50, 100);
  //   await incrementProgress(80, 100);
  //   try {
  //     await axios
  //       .post(process.env.REACT_APP_API_URL + '/api/v1/copilot/qdrant-upload-doc/', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: 'Token ' + localStorage.getItem('token')
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           const actualProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //           if (actualProgress >= 100) {
  //             setProgress(100);
  //           }
  //         }
  //       })
  //       .then((res) => {
  //         handleResponse(res, file);
  //       });
  //   } catch (error) {
  //     console.error('Upload failed:', error);
  //   }
  // };
  return (
    <div className=''>
      <Header name={"AI Copilot"} bell={bell} search={search} />
      
      <div className={`ai-container ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className={`copilot-page ${isMenuOpen ? 'reduced-width' : ''}`}>

          
        <div className='upload-mainpage bg-[#FEF9F7] flex justify-center items-center'>
          <div className=" flex justify-center">
          <div className={`${isMenuOpen ? 'ml-7' : 'ml-12'}`}>
            <div className="flex flex-col items-left">
              <h1 className="text-xl font-bold mt-5">
              Hi {user.firstName}! <span className='font-medium'>Welcome to</span> <span className='text-[#8B55DD] font-semibold'>DocuSensa</span> 
              </h1>
              <p className="  ">
              Let's analyze your documents today!
              </p>
            </div>

            <div className="mt-4">
              <div className={`upload-section flex items-center justify-center ${isMenuOpen ? 'w-[1070px] h-40' : 'w-[1230px] h-40'}`}>

            {status === "Completed" ? (
              <FileSuccessfullyUploaded apiResponse={apiResponse}/>
            ) : uploadFile ? (
              <div>
                <Documentupload progress={progress}/>
              </div>
            ) : (
              <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center cursor-pointer"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <div className='relative'>
                      <img src={backup} alt='upload document' className='backup-logo'/>
                      <img src={plus} alt='upload document' className='plus-logo absolute bottom-4 right-1'/>
                    </div>
                    <p className="mb-3 text-base font-bold text-[#BB42AE]">
                      <span className="font-medium text-[#171717]">Drag and drop your document here or  </span> click to browse.
                    </p>
                    <p className="text-[#696969] text-sm font-medium">
                    Supported formats:pdf, csv, xml
                    </p>
                  </div>
                  <input
                    onChange={uploadDocument}
                    // onClick={openModal}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </label>
            )}  
              </div>
            </div>

            {/* summarize or extract data  */}
            {status === "Completed" && 
              <div className="mt-6" >
                <div className={`h-[74px] rounded-md flex justify-between px-5 ${isMenuOpen ? 'w-[1070px]' : 'w-[1230px]'}`} style={{border: "1px solid rgba(0, 0, 0, 0.10)"}}>
                  <div className='flex gap-2 justify-center items-center'>
                    <div className=''>
                      <img src={blueai} alt='ai-logo'/>
                    </div>
                    <div>
                      <h2 className='text-sm font-semibold text-[#171717]'>{apiResponse.data.data.document_type}</h2>
                      <p className='text-[#3F3F46] font-medium text-sm'>Would you like to extract key clauses, or should I summarize it for you?</p>
                  </div>
                  </div>
                  <div className='flex justify-center items-center gap-[10px]'>
                    <button className='flex justify-center items-center rounded-xl ' onClick={handleSummarize} style={{padding: "10px 28px", border: "1px solid #C95EBE"}}>
                      <img src={blueai} alt='ai-logo'/>
                      <p className='text-base font-semibold text-[#C95EBE]'>Summarize</p>
                    </button>
                    <button className='flex justify-center items-center rounded-xl gap-[10px] bg-[#C95EBE]' style={{padding: "10px 28px", border: "1px solid #C95EBE"}}
                    onClick={()=>navigate('/Contract/contract-details', { state: { contractKey } })}
                    >
                      <img src={findImage} alt='find-logo'/>
                      <p className='text-base font-semibold text-[#FAF9F6]'>Extract data</p>
                    </button>
                  </div>
                
                </div>
                
              </div>
            }

            <div className={`${status === "Completed" ? "mt-9" : "mt-[120px]"} flex justify-center`}>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-[12px] justify-items-center w-[664px]'>
                {card.map((item, index) => (
                  <div key={index} className='card-question'>
                    <div className="flex justify-center items-center text-[#73607B] text-base w-[268px] font-medium">
                      {item.title}  
                    </div>
                  </div>
                ))}
              </div> 
            </div>
            <div className='mt-11'>
              <div className={`chat-section ${isMenuOpen ? 'gap-[12px] w-[1072px]' : 'gap-[12px] w-[1230px]'}`}>
                <input
                  type='text'
                  className='chat-input'
                  placeholder='Ask me anything to our AI copilot and lorem ipsum'
                /> 
                <button className='chat-button'>
                  <img src={send} alt="Send Icon" className='send-icon'/>
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
      
      
    
  
  )
}
import React , {useState}from "react";
import ChatSidebar from "./ChatSidebar";
import Menu from "./Menu";

const Layout = ({ children, open=true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
console.log("menu open", isMenuOpen);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };
  return (
    <div className="chat-container flex flex-row justify-evenly h-full md:h-screen w-[100%]">
      <div>
        {/* <ChatSidebar /> */}
        <Menu isOpen={open ? isMenuOpen: !isMenuOpen} toggleMenu={toggleMenu} />
      </div>
      <div className="ml-14 w-full">
      {React.cloneElement(children, { isMenuOpen })}
        </div>
    </div>
  );
};

export default Layout;

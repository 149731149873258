import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";

function GoogleSignInButton(props) {


  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  /*global google*/
  let client;
  
  const handleGoogleLogin = async (response) => {
    console.log(response, "response");

    try {
      // Call the googleLogin function to get user data (like email)
      const res = await googleLogin(response.code, hostname);
      
      // Assuming the email is returned in the response (adjust as necessary)
      const email = res.data?.email; 
      if (email) {
        localStorage.setItem("email", email); // Save email to localStorage
      }
      
      // Continue with login success actions
      props.setVerified(true);
      props.loginMessage();
      navigate("/ai-copilot");
    } catch (error) {
      console.error("Google login failed", error);
    }
  };

  if (typeof google !== "undefined") {
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      callback: handleGoogleLogin,
    });
  } else {
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: handleGoogleLogin,
      });
    }
    wait_for_1_sec();
  }

  // const hostname = window.location.hostname;
  // const navigate = useNavigate();
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // /*global google*/
  // let client;
  // if (typeof google != "undefined") {
  //   client = google.accounts.oauth2.initCodeClient({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     scope:
  //       "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  //     callback: async (response) => {
  //       console.log(response, "response")

  //       await googleLogin(response.code, hostname).then((res) => {
  //         props.setVerified(true);
  //         props.loginMessage();
  //         navigate("/uploadFile")
  //       });
  //     },
  //   });
  // } else {
  //   async function wait_for_1_sec() {
  //     await delay(1000);
  //     client = google.accounts.oauth2.initCodeClient({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope:
  //         "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",

  //       callback: async (response) => {
  //         console.log(response, "response")
  //         await googleLogin(response.code, hostname).then((res) => {
  //           props.setVerified(true);
  //           props.loginMessage();
  //           navigate("/uploadFile")
  //         });
  //       },
  //     });
  //   }
  //   wait_for_1_sec();
  // }

  return (
    <button
      id="google-login-btn"
      onClick={() => client.requestCode()}
      className="googlebtn flex w-full items-center"
    >
      <div className="google-icon bg-white h-full w-16 flex justify-center items-center">
        <FcGoogle />
      </div>
      <div className="googleText flex justify-center items-center">
        <div className=" ">Login with your google Account</div>
      </div>
    </button>
  );
}
export default GoogleSignInButton;

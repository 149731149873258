import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./contractDetails.css";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";

const ContractDetails = () => {
  const [contractData, setContractData] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [openSections, setOpenSections] = useState({
    termsAndConditions: false,
    financialTerms: false,
    legalClauses: false,
  });
  const [openClauses, setOpenClauses] = useState({
    termination: false,
    confidentiality: false,
    governing: false,
  });
  const location = useLocation();
  const contractKey = location.state?.contractKey;
  const navigate = useNavigate();

  const closePopup = () => setIsPopupOpen(false);

  
  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  
  const toggleClause = (clause) => {
    setOpenClauses((prevClauses) => ({
      ...prevClauses,
      [clause]: !prevClauses[clause],
    }));
  };

  useEffect(() => {
    const fetchContractDetails = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Session expired, please log in.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/extract-contract-info/`,
          { contract_key: contractKey },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        console.log("API Response for contract details:", response.data);
        if (response.status === 200) {
          setContractData(response.data.contract_data);
        } else {
          alert(response.data.msg || "Failed to fetch contract details.");
        }
      } catch (error) {
        console.log("Fetch error:", error);
        alert(
          error.response?.data.msg ||
            "An error occurred while fetching contract details."
        );
      }
    };

    if (contractKey) {
      fetchContractDetails();
    } else {
      alert("Contract key is missing. Unable to fetch contract details.");
    }
  }, [contractKey]);

  const viewContract = async () => {
    console.log("View Contract button clicked");

    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/view-contract/${contractKey}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      console.log("API Response for view contract:", response.data);
      if (response.status === 200 && response.data.contract_url) {
        setContractUrl(response.data.contract_url);
        setIsPopupOpen(true);
      } else {
        alert(response.data.msg || "Failed to retrieve contract.");
      }
    } catch (error) {
      console.log("Error fetching contract URL:", error);
      alert(
        error.response?.data.msg ||
          "An unexpected error occurred while retrieving the contract."
      );
    }
  };

  return (
    <div className="full_Container">
      <div className="header-container">
        <Header
          icon="/arrow-back.svg" 
          name="Go to Contracts"
          bell={bell}
          search={search}
          askCoPiolt={true}
          contractKey={contractKey}
        />
      </div>
      <div className="contract-details">
        {contractData ? (
          <>
            <header className="header">
              <div className="header-content">
                <div className="contract-info">
                  <img src="/hicon.svg" alt="icon" className="contract-icon" />
                  <div>
                    <h1 className="contract-title">
                      {contractData.contract_title || "N/A"}
                    </h1>
                    <p className="contract-id">
                      Contract ID:{" "}
                      <span>{contractData.contract_id_number || "N/A"}</span>
                    </p>
                    <p className="contract-type">
                      Contract type:{" "}
                      <span>{contractData.contract_type || "N/A"}</span>
                    </p>
                  </div>
                </div>
                <button className="view-contract-button-contract" onClick={viewContract}>
                  <span className="button-icon">
                    <img src="/eye.svg" alt="" />
                  </span>{" "}
                  View Contract
                </button>
              </div>
            </header>
         
            {contractUrl && isPopupOpen && (
              <div className="contract-modal-overlay" onClick={closePopup}>
              <div
                className="contract-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="contract-close-button" onClick={closePopup}>
                  &times;
                </button>
                <iframe
                  src={contractUrl}
                  title="Contract Document"
                  className="contract-iframe"
                ></iframe>
              </div>
            </div>
            
            )}

            <h4 className="customer-header">
              <span role="img" aria-label="icon">
                <img src="/custDetails.svg" alt="" />
              </span>{" "}
              Customer Details
            </h4>
            <section className="customer-details">
              <div className="customer-cards">
                <div className="customer-card">
                  <span className="party-label">Customer Party A:</span>{" "}
                  <strong className="cust-details">
                    {contractData.contract_owner_name || "N/A"}
                  </strong>
                  <p className="signatory">
                    <strong>Customer Signatory:</strong>{" "}
                    <span className="cust-details">
                      {contractData.contract_owner_signatory_name || "N/A"}
                    </span>
                  </p>
                  <p className="address">
                    <strong>Address:</strong>{" "}
                    <span className="cust-details">
                      {contractData.contract_owner_address || "N/A"}
                    </span>
                  </p>
                  <div className="contact-info">
                    <span role="img" aria-label="phone">
                      <img src="/call.svg" alt="" />
                    </span>
                    <span className="phone-data">
                      {contractData.contract_owner_telephone || "N/A"}
                    </span>
                  </div>
                  <div className="contact-info">
                    <span role="img" aria-label="email">
                      <img src="/message.svg" alt="" className="email-img" />
                    </span>
                    <span className="email-data">
                      {contractData.contract_owner_email || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="customer-card">
                  <span className="party-label">Customer Party B:</span>{" "}
                  <strong className="cust-details">
                    {contractData.customer_name || "N/A"}
                  </strong>
                  <p className="signatory">
                    <strong>Customer Signatory:</strong>{" "}
                    <span className="cust-details">
                      {contractData.customer_signatory_name || "N/A"}
                    </span>
                  </p>
                  <p className="address">
                    <strong>Address:</strong>{" "}
                    <span className="cust-details">
                      {contractData.customer_address || "N/A"}
                    </span>
                  </p>
                  <div className="contact-info">
                    <span role="img" aria-label="phone">
                      <img src="/call.svg" alt="" />
                    </span>
                    <span className="phone-data">
                      {contractData.customer_telephone || "N/A"}
                    </span>
                  </div>
                  <div className="contact-info">
                    <span role="img" aria-label="email">
                      <img src="/message.svg" alt="" className="email-img2" />
                    </span>
                    <span className="email-data">
                      {contractData.customer_email || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section className="key-dates">
              <div className="key-dates-header">
                <div className="key-dates-title">
                  <img src="/key-dates.svg" alt="Key Dates Icon" />
                  <h3>Key Dates</h3>
                </div>
                <p className="key-dates-description">
                  View all your important key dates.
                </p>
              </div>

              <div className="date-details">
                <p className="effective">
                  Effective date:{" "}
                  <span className="keyValue">
                    {contractData.effective_date || "N/A"}
                  </span>
                </p>
                <p className="effective">
                  Execution date:{" "}
                  <span className="keyValue">
                    {contractData.execution_date || "N/A"}
                  </span>
                </p>
                <p className="effective">
                  Payment date 1:{" "}
                  <span className="keyValue">
                    {contractData.payment_date_1 || "N/A"}
                  </span>
                </p>
                <p className="effective">
                  Expiration date:{" "}
                  <span className="keyValue">
                    {contractData.expiration_date || "N/A"}
                  </span>
                </p>
                <p className="effective">
                  Renewal date:{" "}
                  <span className="keyValue">
                    {contractData.renewal_date || "N/A"}
                  </span>
                </p>
                <p className="effective">
                  Payment date 2:{" "}
                  <span className="keyValue">
                    {contractData.payment_date_2 || "N/A"}
                  </span>
                </p>
              </div>

              <div className="milestone-section">
                <h4 className="milestone-header">
                  Milestone Dates
                  <span className="toggle-button">
                    <img src="/milestone.svg" alt="Milestone Icon" />
                  </span>
                </h4>
                <div className="milestone-cards">
                  
                  {contractData.milestone_date_1 && (
                    <div className="milestone-card">
                      <div className="milestone-header">
                        <div className="milestone-date">
                          <span
                            className="calendar-icon"
                            role="img"
                            aria-label="calendar"
                          >
                            <img src="/calendarmilestone.svg" alt="" />
                          </span>
                          <strong>
                            {contractData.milestone_date_1 || "N/A"}
                          </strong>
                        </div>
                        <span className="view-more">View More</span>
                      </div>
                      <p className="milestone-description">
                        {contractData.milestone_date_1_desc ||
                          "No description available"}
                      </p>
                    </div>
                  )}

                 
                  {contractData.milestone_date_2 && (
                    <div className="milestone-card">
                      <div className="milestone-header">
                        <div className="milestone-date">
                          <span
                            className="calendar-icon"
                            role="img"
                            aria-label="calendar"
                          >
                            <img src="/calendarmilestone.svg" alt="" />
                          </span>
                          <strong>
                            {contractData.milestone_date_2 || "N/A"}
                          </strong>
                        </div>
                        <span className="view-more">View More</span>
                      </div>
                      <p className="milestone-description">
                        {contractData.milestone_date_2_desc ||
                          "No description available"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="terms-and-conditions-section">
              <div className="accordion-item">
                <div
                  className="accordion-header"
                  onClick={() => toggleSection("termsAndConditions")}
                >
                  <p>
                    <span className="icon-text">
                      <img src="/term.svg" alt="" className="icon" />
                      Terms and Conditions
                    </span>
                  </p>
                  <img
                    src={
                      openSections.termsAndConditions ? "/up.svg" : "/down.svg"
                    }
                    alt={
                      openSections.termsAndConditions ? "Collapse" : "Expand"
                    }
                    className="toggle-icon-term"
                  />
                </div>

                {openSections.termsAndConditions && (
                  <div className="accordion-content">
                    <div className="terms-and-conditions-content">
                      <div className="contract-duration">
                        <p className="label-terms">Contract Duration</p>
                        <p className="value">
                          {contractData.contract_duration || "N/A"}
                        </p>
                      </div>
                      <div className="payment-terms">
                        <p className="label-terms">Payment Terms</p>
                        <p className="value">
                          {contractData.payment_terms || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="clause-header"
                      onClick={() => toggleClause("termination")}
                    >
                      <p>Termination Clause</p>
                      <img
                        src={openClauses.termination ? "/up.svg" : "/down.svg"}
                        alt={openClauses.termination ? "Collapse" : "Expand"}
                        className="toggle-icon"
                      />
                    </div>
                    {openClauses.termination && (
                      <div className="clause-content">
                        <p>{contractData.termination_clause || "N/A"}</p>
                      </div>
                    )}

                    <div
                      className="clause-header"
                      onClick={() => toggleClause("confidentiality")}
                    >
                      <p>Confidentiality Clause</p>
                      <img
                        src={
                          openClauses.confidentiality ? "/up.svg" : "/down.svg"
                        }
                        alt={
                          openClauses.confidentiality ? "Collapse" : "Expand"
                        }
                        className="toggle-icon"
                      />
                    </div>
                    {openClauses.confidentiality && (
                      <div className="clause-content">
                        <p>{contractData.confidentiality_clause || "N/A"}</p>
                      </div>
                    )}

                    <div
                      className="clause-header"
                      onClick={() => toggleClause("governing")}
                    >
                      <p>Governing Law</p>
                      <img
                        src={openClauses.governing ? "/up.svg" : "/down.svg"}
                        alt={openClauses.governing ? "Collapse" : "Expand"}
                        className="toggle-icon"
                      />
                    </div>
                    {openClauses.governing && (
                      <div className="clause-content">
                        <p>{contractData.governing_law || "N/A"}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </section>

            <section className="financial-terms-section">
              <div className="accordion-item">
                <div
                  className="accordion-header"
                  onClick={() => toggleSection("financialTerms")}
                >
                  <p className="financial">
                    <span>
                      <img src="/financial.svg" alt="Financial Icon" />
                    </span>{" "}
                    Financial Terms
                  </p>
                  <img
                    src={
                      openSections.termsAndConditions ? "/up.svg" : "/down.svg"
                    }
                    alt={
                      openSections.termsAndConditions ? "Collapse" : "Expand"
                    }
                    className="toggle-icon-fin"
                  />
                </div>
                {openSections.financialTerms && (
                  <div className="accordion-content">
                    <p className="accord-cont">
                      {contractData.financial_terms || "N/A"}
                    </p>
                  </div>
                )}
              </div>
            </section>

            <section className="legal-clauses-section">
              <div className="accordion-item">
                <div
                  className="accordion-header"
                  onClick={() => toggleSection("legalClauses")}
                >
                  <p className="financial">
                    <span>
                      <img src="/legal.svg" alt="Legal Icon" />
                    </span>{" "}
                    Legal Clauses
                  </p>
                  <img
                    src={
                      openSections.termsAndConditions ? "/up.svg" : "/down.svg"
                    }
                    alt={
                      openSections.termsAndConditions ? "Collapse" : "Expand"
                    }
                    className="toggle-icon-legal"
                  />
                </div>
                {openSections.legalClauses && (
                  <div className="accordion-content">
                    <p className="accord-cont">
                      {contractData.legal_clauses || "N/A"}
                    </p>
                  </div>
                )}
              </div>
            </section>

            <section className="notes-section">
              <div className="notes-item">
                <div className="notes-header">
                  <p className="notes">
                    <span>
                      <img src="/notes.svg" alt="" />
                    </span>{" "}
                    Notes
                  </p>
                </div>
                <textarea
                  placeholder="Enter your comments here..."
                  className="notes-input"
                  defaultValue={contractData.user_note || ""}
                ></textarea>
              </div>
            </section>
          </>
        ) : (
          <p>Loading contract details...</p>
        )}
      </div>
    </div>
  );
};

export default ContractDetails;

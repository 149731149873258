import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './otp.css'
import close from '../../../Images/close.jpg'
import verifylogo from "../../../Images/Succes-email.png"
const OtpPage = ({ closeModal, token, setEmailAccountVerified }) => {
  
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState('');
  const [verify, setVerify] = useState(false)

  const inputs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  console.log("token on otp page", token);

  const navigate = useNavigate();


  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    setEmail(storedEmail);
    if (inputs[0].current) {
      inputs[0].current.focus(); 
    }
  }, []);

  const handleInput = (e, index) => {
    const input = e.target;
    if (index < inputs.length - 1 && input.value !== "") {
      inputs[index + 1].current.focus();
    }
    const newOtp = otp.slice(0, index) + input.value + otp.slice(index + 1);
    setOtp(newOtp);
  };

  // const handleInput = (e, index) => {
  //   const input = e.target.value;

  //   // Allow only one character input and ensure it's numeric
  //   if (!/^\d*$/.test(input) || input.length > 1) return;

  //   const newOtpArray = [...otp];
  //   newOtpArray[index] = input; 
  //   const newOtp = newOtpArray.join(""); 
  //   setOtp(newOtp);

  //   // Move to the next input if filled
  //   if (input && index < inputs.length - 1) {
  //     inputs[index + 1].current.focus();
  //   } 
  // };
  

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      inputs[index - 1].current.focus();
    }
  };


  const handleVerify = async () => {
    // e.preventDefault();

    console.log("Email:", email);
    console.log("OTP:", otp);

    const data = {
      email: email,
      otp: otp,
    };

    try {
     const response =  await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/accounts/verify/", 
        data, 
        {
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
    console.log("otp verification", response);
    if(response.data.message === "Account verified"){
      toast.success("Account verified", {
        autoClose: 1000,
      });
      setVerify(true)
      setEmailAccountVerified(true)
    }
    else if (response.data.data === "Wrong OTP") {
      toast.warn("Wrong OTP", {
        autoClose: 2000,
      });
    } else if (response.data.data === "Invalid email") {
      toast.warn("Invalid email", {
        autoClose: 2000,
      });
    }
    } catch (error) {
      console.error("Error while verifying OTP:", error);
    }
  };

  const handleResendOTP = async () => {
    
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/resend-otp/",
        null,
        { headers }
      )
      console.log("resend response", response);
      if(response.data.message === "New OTP sent to your email address."){
        toast.success("Otp resend", {
          autoClose: 1000,
        });
        setOtp("");  
        inputs.forEach(input => input.current.value = "");
      }
      else if(response.data.message === "Your account is already verified."){
        toast.success("Account already verified", {
          autoClose: 1000,
        });
      }
      
    } catch (error) {
      console.error("Error while resending OTP:", error);
    }
  };
  return (
    <div className="absolute main-otppage z-50">
      <div className=" flex items-center justify-center ">

    {verify ? (
      <div className="otpverify-container relative bg-white h-auto text-center">
        <div>
          <div className="flex justify-center items-center mt-8 ">
            <img src={verifylogo} alt="email verify logo" className="w-[245px] h-[98px]"/>
          </div>
          <div className="mt-7">
              <h2 className="verify-topHeading">Your E-mail has been Verified</h2>
              <p className="verify-subHeading">Your email has been successfully verified.</p>
          </div>
          <div>
            <button
            onClick={closeModal}
            className="close-verifybtn text-white text-center mt-9"
          >
            Close
          </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="otp-container relative bg-white h-auto text-center">
          <div className="text-left">
            <h1 className="top-heading">Verify Your Email ID</h1>
            <div className="sub-heading w-[415px] mt-4">
              <p>Please enter the 6 digit Verification code that has been sent to {email.replace(/(.{2}).+(@.+)/, "$1********$2")}</p>
              {/* <span className="font-bold">{email}</span> */}
            </div>
          </div>
          <button className="absolute top-5 right-9" onClick={closeModal}>
            <img src={close} alt="close button"/>
          </button>
          

          <div className="flex flex-row gap-5 items-center pl-9 mt-4">
            {inputs.map((inputRef, index) => (
              <input
                key={index}
                className="otp-box"
                type="text"
                ref={inputRef}
                maxLength="1"
                value={otp[index] || ""}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
              />
            ))}
          </div>

          <div className="resend-btn flex justify-end mt-[12px] resent-btn">
              <button onClick={handleResendOTP} className="">
                Resend OTP
              </button>
              {/* <BsArrowRightShort /> */}
           
          </div>

          <div className="flex justify-start ml-8 mt-4">
            <button
              onClick={handleVerify}
              className="verify-btn text-center"
            >
              Verify E-mail
            </button>
          </div>

         
          
        </div>
    )}

        
      </div>
    </div>
  );
};

export default OtpPage;
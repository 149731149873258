import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Header } from "../../components/Header/Header";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import axios from "axios";
import "./UploadFilePage.css";
import { toast } from "react-toastify";
import StatusModal from "./StatusModal";
import UploadModal from "../Contract/UploadModal";

const UploadFilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [token, setToken] = useState(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [editContractId, setEditContractId] = useState(null); // Track which contract is in edit mode
  const [editedContractData, setEditedContractData] = useState({}); // Store edited row data
  const [currentPage, setCurrentPage] = useState(1); // Start on the first page
  const totalPages = 44; // Replace with dynamic data if needed
  const editRowRef = useRef(null);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const pdf_key = localStorage.getItem("pdfKey");
  console.log("hsjh", pdf_key);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

  const fetchContracts = useCallback(async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/pdfs/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("Fetched contracts:", response.data);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    }
  }, [token]);

  const handleSelectAll = () => {
    if (selectedContracts.length === files.length) {
      setSelectedContracts([]); // Deselect all
    } else {
      setSelectedContracts(files.map((file) => file.id)); // Select all
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedContracts.includes(id)) {
      setSelectedContracts(
        selectedContracts.filter((contractId) => contractId !== id)
      );
    } else {
      setSelectedContracts([...selectedContracts, id]);
    }
  };

  useEffect(() => {
    if (token) {
      fetchContracts();
    }
  }, [fetchContracts, token]);

  const handleUploadSuccess = () => {
    // fetchContracts();
    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
  };

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const toggleActionMenu = (index) => {
    setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  // const handleViewMore = (contractKey) => {
  //   // const condition = "table";
  //   navigate("/Contract/contract-details", { state: { contractKey } });
  // };

  // const handleViewMore = (contractKey = "_124_175_6bbc27b6-495e-4580-80cc-22f49e08d777") => {
  //   console.log("Navigating with contractKey:", contractKey);
  //   navigate("/Contract/contract-details", { state: { contractKey } });
  // };
  const handleViewMore = () => {
    const pdfKey = localStorage.getItem("pdfKey");
    if (!pdfKey) {
      console.error("PDF key is missing from localStorage!");
      alert("PDF key is not available.");
      return;
    }
    console.log("Navigating with pdfKey as contractKey:", pdfKey);
    navigate("/Contract/contract-details", { state: { contractKey: pdfKey } });
  };

  const enableEdit = (contract) => {
    if (contract) {
      setIsActionMenuOpen(false);
      setEditContractId(contract.id);
      setEditedContractData({ ...contract });
    } else {
      setEditContractId(null); // Reset edit mode
      setEditedContractData({});
    }
  };

  const handleEditChange = (field, value) => {
    setEditedContractData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const goToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return; // Prevent out-of-range pages
    setCurrentPage(pageNumber);
  };

  const handleClickOutside = (event) => {
    if (editRowRef.current && !editRowRef.current.contains(event.target)) {
      enableEdit(); // Reset edit mode when clicking outside
      // handleEditSave()
    }
  };
  const handleIncreasePage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handleDecreasePage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  var intervalId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const location = useLocation();

  // console.log(location.state.file, "location ************88888i");

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      // console.log("selectedFile", e.target.files[0]);

      const fileType = ["application/pdf"];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          // toast.warn("Invalid file type", {
          //   autoClose: 2000,
          // });
          // reject(new Error("Invalid file type"));
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleResponse = (res, file) => {
    // console.log("res data is ---", res)
    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.pdf_key) {
      console.log("PDF key received:", res.data.pdf_key);
      localStorage.setItem("pdfKey", res.data.pdf_key); // Store pdf_key regardless of status
    }

    // console.log(res.data.status, "data status is------")
    switch (res.data.status) {
      case "STARTING":
        console.log("STARTING: PDF key received:", res.data.pdf_key);
        // localStorage.setItem("pdfKey", res.data.pdf_key);
        return setStatus("Uploading Pdf ..");
      case "LOADING":
        return setStatus("Loading Pdf ...");
      case "ANALYZING":
        return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        return setStatus("Summarizing Pdf ...");
      case "PENDING":
        return setStatus("Maybe Asynchronous task failed...");
      case "FAILED":
        return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        clearInterval(intervalId);
        setStatus("Upload successful!");
        // console.log(res.data, 'This is updated data')
        return location.pathname === "/test"
          ? navigate("/test/c", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            })
          : navigate("/chat", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            });
      default:
        console.error(`Unknown status from server ${res.data.status}`);
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  const handleDelete = (contractId) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setContractToDelete(contractId);
    setConfirmDeleteVisible(true);
  };

  const handleEditSave = async (contractId) => {
    if (!editedContractData.contract_name) {
      showPopup("Error", "Contract name is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-name/`,
        {
          contract_key: editedContractData?.key,
          new_contract_name: editedContractData?.contract_name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((filee) =>
            filee.id === filee ? editedContractData : filee
          )
        );
        setEditContractId(null);
        showPopup("Success", "Contract updated successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to update contract: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to update contract: No response received from the server."
        );
      } else {
        showPopup("Error", "Failed to update contract. Please try again.");
      }
    }
  };

  const confirmDelete = async () => {
    if (contractToDelete && token) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/v1/readpdf/pdfs/${contractToDelete}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.status === 204) {
          // Assuming 204 means successful deletion
          setFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== contractToDelete)
          );
          showPopup("Success", "PDF deleted successfully.");
        } else {
          showPopup("Error", "Failed to delete PDF. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting PDF:", error);
        showPopup("Error", "Failed to delete PDF. Please try again.");
      } finally {
        setConfirmDeleteVisible(false);
        setContractToDelete(null);
      }
    }
  };

  useEffect(() => {
    if (editContractId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContractId]);

  const upload = async (e) => {
    // Check if a file is actually selected
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      console.log("No file selected");
      return; // Exit the function if no file is selected
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let file = null;
    handleFile(e).then((res) => {
      file = res;
    });
    // console.log("formData", formData);
    try {
      // let file = await handleFile(e)
      const token = localStorage.getItem("token");
      // console.log(token, "this is token")
      setLoading(true);
      setStatus("Uploading...");
      await axios
        .post(
          // process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-async-fast/",
          process.env.REACT_APP_BASE_URL +
            `/api/v1/readpdf/qdrant-upload-async/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("API Response:", res); // Debugging the full response
          // handleResponse(res, file);
          // intervalId = setInterval(() => getPdfStatus(file), 1000);
        });
    } catch (err) {
      setLoading(false);
      setStatus("Error during file upload");
      if (
        err.response &&
        err.response.data &&
        err.response.data.msg === "Unsupported file format"
      ) {
        toast.warn("Unsupported file format", { autoClose: 2000 });
      } else {
        console.error("Error during file upload:", err);
        toast.error("An error occurred during file upload", {
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <div className="document-full-container">
      <div className="">
        <Header name="Documents" icon={""} bell={bell} search={search} />
      </div>

      <div className="document-contract-table-container">
        <div className="document-contract-table-header">
          <div className="document-search-filter-container">
            <div className="document-search-box">
              <img src="search.svg" alt="" />
              <input
                type="text"
                placeholder="Search Contracts Here"
                className="document-input-space"
              />
            </div>
            <button
              className="document-filter-button"
              onClick={toggleFilterModal}
            >
              <img src="/filter.svg" alt="Filter Icon" />
            </button>
          </div>
          {selectedContracts.length > 0 ? (
            <div className="document-button-cat">
              <button>
                <img src="/export_icon.svg" alt="Export Icon" /> Export
              </button>
              <button>
                <img src="/delete_icon.svg" alt="Delete Icon" /> Delete
              </button>
              <button>
                <img src="/cancel_icon.svg" alt="Cancel Icon" /> Cancel
              </button>
            </div>
          ) : (
            <div className="document-upload-section">
              <button className="document-upload-button1" onClick={toggleModal}>
                <img src="/upload-doc.svg" alt="Upload Icon" /> Create Folder
              </button>

              <button
                className="document-upload-button2"
                // onClick={() => document.getElementById("dropzone-file").click()}
                onClick={toggleModal}
              >
                <img src="/upload_icon-doc.svg" alt="Upload Icon" /> Upload
              </button>

              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={upload} // Your existing upload function
                // onClick={openModal}
                // Your existing openModal function
              />

              {loading && (
                <StatusModal
                  closeModal={closeModal}
                  status={status}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>

        <div className="document-container">
          <table className="document-contract-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="document-custom-checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedContracts.length === files.length &&
                      files.length > 0
                    }
                  />
                </th>
                <th>File Name</th>
                <th>File Type</th>
                <th>Size</th>
                <th>Uploaded Date</th>
                {/* <th>Chat</th>
            <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? (
                files.map((file, index) => (
                  <tr
                    key={index}
                    ref={editContractId === file.id ? editRowRef : null}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="document-custom-checkbox"
                        checked={selectedContracts.includes(file.id)}
                        onChange={() => handleCheckboxChange(file.id)}
                      />
                    </td>
                    <td className="file-name-cell">
                      <div className="file-icon-and-name">
                        <img
                          src="/pdf.svg"
                          alt="PDF Icon"
                          className="file-icon"
                        />
                        <div className="file-details">
                          <div className="file-name">
                            {file.pdf_name || "N/A"}
                          </div>
                          <div className="file-type">Pdf</div>
                        </div>
                      </div>
                    </td>

                    {editContractId === file.id ? (
                      <>
                        <td>
                          <input
                            type="text"
                            value={editedContractData.contract_name || ""}
                            onChange={(e) =>
                              handleEditChange("contract_name", e.target.value)
                            }
                            className="document-edit-contract"
                            style={{ color: "#B793ED" }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={
                              editedContractData.contract_owner_name || "N/A"
                            }
                            onChange={(e) =>
                              handleEditChange(
                                "contract_owner_name",
                                e.target.value
                              )
                            }
                            className="document-edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={editedContractData.customer_name || ""}
                            onChange={(e) =>
                              handleEditChange("customer_name", e.target.value)
                            }
                            className="document-edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            value={editedContractData.effective_date || ""}
                            onChange={(e) =>
                              handleEditChange("effective_date", e.target.value)
                            }
                            className="document-edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            value={editedContractData.expiration_date || ""}
                            onChange={(e) =>
                              handleEditChange(
                                "expiration_date",
                                e.target.value
                              )
                            }
                            className="document-edit-contract"
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => handleEditSave(file.pdf_name)}
                            className="document-save-design"
                          >
                            Save
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={{ color: "#B793ED" }}>
                          {file.document_type || "N/A"}
                        </td>
                        <td>{file.document_size || "N/A"}</td>
                        <td>{file.uploaded_at || "N/A"}</td>
                        <td className="chat-button-cell">
                          <div
                            className="chat-button-doc"
                            onClick={() => {
                              console.log(
                                "Navigating to chat with key:",
                                file.key
                              );
                              navigate("/chat", {
                                state: { pdfKey: file.key },
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="/chat-icon.svg"
                              alt="Chat Icon"
                              className="chat-icon"
                            />
                            <span>Chat</span>
                          </div>
                        </td>

                        <td>
                          <img
                            src="./action.svg"
                            alt="Actions"
                            width="20"
                            height="20"
                            onClick={() => toggleActionMenu(index)}
                            style={{ cursor: "pointer" }}
                          />
                          {isActionMenuOpen === index && (
                            <div className="document-action-menu">
                              <div
                                className="document-action-item"
                                onClick={() => {
                                  console.log("File pdf_key:", file.pdf_key); // Log pdf_key here
                                  handleViewMore(file.pdf_key);
                                }}
                              >
                                <img
                                  src="./viewmore.svg"
                                  alt="View More Icon"
                                  className="document-action-icon"
                                />
                                <p>View More</p>
                              </div>

                              <div
                                className="document-action-item"
                                onClick={() => enableEdit(file)}
                              >
                                <img
                                  src="./edit.svg"
                                  alt="Edit Icon"
                                  className="document-action-icon"
                                />
                                <p>Rename Folder</p>
                              </div>
                              <div
                                className="document-action-item"
                                onClick={() => handleDelete(file.id)}
                              >
                                <img
                                  src="./delete.svg"
                                  alt="Delete Icon"
                                  className="document-action-icon"
                                />
                                <p>Delete Folder</p>
                              </div>
                            </div>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="document-no-data">
                    No contracts available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="document-pagination">
            <div className="document-pagination-dropdown">
              <select
                value={currentPage}
                onChange={handlePageChange}
                className="document-page-selector"
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <p className="document-content-down">of {totalPages} pages</p>
            <button>
              <img
                src="decrease.svg"
                alt="Previous Page"
                onClick={handleDecreasePage}
                style={{ cursor: "pointer" }}
              />
            </button>
            <button>
              <img
                src="increase.svg"
                alt="Next Page"
                onClick={handleIncreasePage}
                style={{ cursor: "pointer" }}
              />
            </button>
          </div>
        </div>
      </div>

      {popupVisible && (
        <div className="document-popup-overlay">
          <div className="document-popup-box">
            <h2>{popupTitle}</h2>
            <p>{popupMessage}</p>
            <button
              onClick={closePopup}
              className="document-popup-close-button"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {confirmDeleteVisible && (
        <div className="document-popup-overlay">
          <div className="document-popup-box-delete">
            <h2>Confirm Deletion</h2>
            <p>
              Once deleted, this Folder and its related documents will be
              permanently removed. This action cannot be undone.
            </p>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                marginTop: "20px",
                marginLeft: "-100px",
              }}
            >
              <button
                onClick={confirmDelete}
                className="document-popup-close-button"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setConfirmDeleteVisible(false)}
                className="document-popup-close-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUploadSuccess={handleUploadSuccess}
        type={"document"}
      />
    </div>
  );
};

export default UploadFilePage;

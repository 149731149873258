import React from 'react'
import checkCircle from '../../Images/check_circle.png'
import cancel from "../../Images/cancel.jpg"
export const FileSuccessfullyUploaded = ({apiResponse}) => {

    // const fileName = apiResponse?.data?.msg || "document"
    const fileName = apiResponse?.data?.filename || "";
//   const fileNameMatch = message.match(/(\S+\.pdf)/);
//   const fileName = fileNameMatch ? fileNameMatch[0] : "document";
  return (
    <div>
        <label
            for="dropzone-file"
            className="flex flex-col items-center justify-center cursor-pointer"
        >
            <div className="flex flex-col items-center justify-center pt-4">
            <div className=''>
                <img src={checkCircle} alt='upload document' className='backup-logo'/>
                {/* <img src={plus} alt='upload document' className='plus-logo absolute bottom-4 right-1'/> */}
            </div>
            <p className="mt-4 text-base font-medium text-[#171717]">
                File uploaded successfully 
            </p>

            <div className="flex justify-center gap-4 rounded-xl text-[#3F3F46] text-[14px] font-medium mt-3" style={{border: "1px solid rgba(23, 23, 23, 0.10", padding: "6px 16px 7px 16px"}}>
                <div className=''><span className='text-[#18101C]'>{fileName}</span> has been succesfully upoladed
                </div>
                <img src={cancel} alt='cancel'/>
            </div>

            {/* <div className='upload-progressLine h-2 w-[795px] rounded-2xl bg-[#D9D9D9] mt-3 mb-3'>
                <div className='active-progressLine  h-2 w-[551px] rounded-2xl bg-[#C95EBE]'></div>
            </div> */}
            </div>
            {/* <div>
            
            </div> */}
            {/* <input
            onChange={upload}
            onClick={openModal}
            id="dropzone-file"
            type="file"
            className="hidden"
            /> */}
        </label>
        
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import paymentSuccess from '../../Images/Payment-Success-icon.png'

const PaymentSuccess = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const getBackHome = () =>{
    navigate("/ai-Copilot")
  }
  
  useEffect(() => {
    const storeEmail = localStorage.getItem("email");
    setEmail(storeEmail);
  }, []);

  return (
    <div className="success-page flex justify-center items-center">
      <div className="flex items-center justify-center ">
        <div className="payment-container bg-white h-[332px] w-[779px] rounded-xl  text-center">
          <div className="flex justify-center items-center flex-col">
            <div className="flex justify-center items-center mt-5">
              <img
                src={paymentSuccess}
                alt="email verify logo"
                className="w-[245px] h-[98px]"
              />
            </div>
            <div className="text-paymentpage mt-2">
              <h2 className="payment-topHeading text-[#171717] text-center text-xl font-semibold">
                Your Payment Successfully Processed
              </h2>
              <p className="payment-subHeading  text-[#52525B] text-center text-sm font-bold w-[415px]">
                An Invoice has been sent to {email?.replace(/(.{2}).+(@.+)/, "$1********$2")} lorem ipsumlorem ipsumlorem
                ipsumlorem ipsum
              </p>
            </div>
            <div className="flex justify-center items-center gap-3">
              <button
                onClick={getBackHome}
                className="home-btn text-[#C95EBE] text-base font-medium"

              >
                Go to Home
              </button>
              <button className="invite-btn px-[60px] py-3 text-white gap-3 rounded-xl bg-[#C95EBE]">Invite Members</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

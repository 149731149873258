import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import UploadModal from "./UploadModal";
import FilterModal from "./filterModal";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import axios from "axios";
import "./contract_data.css";

const ContractTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [token, setToken] = useState(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [editContractId, setEditContractId] = useState(null); // Track which contract is in edit mode
  const [editedContractData, setEditedContractData] = useState({}); // Store edited row data
  const [currentPage, setCurrentPage] = useState(1); // Start on the first page
  const totalPages = 44; // Replace with dynamic data if needed
  const editRowRef = useRef(null);
  const [selectedContracts, setSelectedContracts] = useState([]);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
const [contractToDelete, setContractToDelete] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };
  

  const fetchContracts = useCallback(async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/contracts/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("Fetched contracts:", response.data);
      setContracts(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    }
  }, [token]);

  const handleSelectAll = () => {
    if (selectedContracts.length === contracts.length) {
      setSelectedContracts([]); // Deselect all
    } else {
      setSelectedContracts(contracts.map((contract) => contract.id)); // Select all
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedContracts.includes(id)) {
      setSelectedContracts(
        selectedContracts.filter((contractId) => contractId !== id)
      );
    } else {
      setSelectedContracts([...selectedContracts, id]);
    }
  };

  useEffect(() => {
    if (token) {
      fetchContracts();
    }
  }, [fetchContracts, token]);

  const handleUploadSuccess = () => {
    fetchContracts();
    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
    fetchContracts();
  };

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const toggleActionMenu = (index) => {
    setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleViewMore = (contractKey) => {
    const condition = "table";
    navigate("/Contract/contract-details", { state: { contractKey } });
  };

  const enableEdit = (contract) => {
    if (contract) {
      setIsActionMenuOpen(false);
      setEditContractId(contract.id);
      setEditedContractData({ ...contract });
    } else {
      setEditContractId(null); // Reset edit mode
      setEditedContractData({});
    }
  };

  const handleEditChange = (field, value) => {
    setEditedContractData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const goToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return; // Prevent out-of-range pages
    setCurrentPage(pageNumber);
  };

  const handleClickOutside = (event) => {
    if (editRowRef.current && !editRowRef.current.contains(event.target)) {
      enableEdit(); // Reset edit mode when clicking outside
      // handleEditSave()
    }
  };
  const handleIncreasePage = () => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };
  
  const handleDecreasePage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  
  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };
  
  const closePopup = () => {
    setPopupVisible(false);
  };
  const handleDelete = (contractId) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setContractToDelete(contractId);
    setConfirmDeleteVisible(true);
  };
  

  const handleEditSave = async (contractId) => {
    if (!editedContractData.contract_name) {
      showPopup("Error", "Contract name is required.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-name/`,
        {
          contract_key: editedContractData?.key,
          new_contract_name: editedContractData?.contract_name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        setContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === contractId ? editedContractData : contract
          )
        );
        setEditContractId(null);
        showPopup("Success", "Contract updated successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to update contract: ${error.response.data.msg || error.response.statusText}`
        );
      } else if (error.request) {
        showPopup("Error", "Failed to update contract: No response received from the server.");
      } else {
        showPopup("Error", "Failed to update contract. Please try again.");
      }
    }
  };
  
  

  const confirmDelete = async () => {
    if (contractToDelete && token) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/contracts/${contractToDelete}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setContracts((prevContracts) =>
          prevContracts.filter((contract) => contract.id !== contractToDelete)
        );
        showPopup("Success", "Contract deleted successfully.");
      } catch (error) {
        console.error("Error deleting contract:", error);
        showPopup("Error", "Failed to delete contract. Please try again.");
      } finally {
        setConfirmDeleteVisible(false);
        setContractToDelete(null);
      }
    }
  };
  

  useEffect(() => {
    if (editContractId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContractId]);

  return (
    <div className="full-container">
      <div className="">
        <Header name="Contracts" icon={""} bell={bell} search={search} />
      </div>

      <div className="contract-table-container">
        <div className="contract-table-header">
          <div className="search-filter-container">
            <div className="search-box">
              <img src="search.svg" alt="" />
              <input type="text" placeholder="Search Contracts Here" className="Input-space"/>
            </div>
            <button className="filter-button" onClick={toggleFilterModal}>
              <img src="/filter.svg" alt="Filter Icon" />
            </button>
          </div>
          {selectedContracts.length > 0 ? (
  <div className="button-cat">
    <button>
      <img src="/export_icon.svg" alt="Export Icon" /> Export
    </button>
    <button>
      <img src="/delete_icon.svg" alt="Delete Icon" /> Delete
    </button>
    <button>
      <img src="/cancel_icon.svg" alt="Cancel Icon" /> Cancel
    </button>
  </div>
) : (
  <button className="upload-button" onClick={toggleModal}>
    <img src="/upload_icon.svg" alt="Upload Icon" /> Upload Contract
  </button>
)}

        </div>

        <div className="container">
          <table className="contract-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedContracts.length === contracts.length &&
                      contracts.length > 0
                    }
                  />
                </th>
                <th>Contract ID</th>
                <th>Contract Name</th>
                <th>Party Involved (A)</th>
                <th>Party Involved (B)</th>
                <th>Effective Date</th>
                <th>Expiration Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {contracts.length > 0 ? (
                contracts.map((contract, index) => (
                  <tr
                    key={index}
                    ref={editContractId === contract.id ? editRowRef : null}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedContracts.includes(contract.id)}
                        onChange={() => handleCheckboxChange(contract.id)}
                      />
                    </td>
                    <td>{contract.id || "N/A"}</td>
                    {editContractId === contract.id ? (
                      <>
                        <td>
                          <input
                            type="text"
                            value={editedContractData.contract_name || ""}
                            onChange={(e) =>
                              handleEditChange("contract_name", e.target.value)
                            }
                            className="edit-contract"
                            style={{ color: "#B793ED" }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={editedContractData.contract_owner_name || ""}
                            onChange={(e) =>
                              handleEditChange(
                                "contract_owner_name",
                                e.target.value
                              )
                            }
                            className="edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={editedContractData.customer_name || ""}
                            onChange={(e) =>
                              handleEditChange("customer_name", e.target.value)
                            }
                            className="edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            value={editedContractData.effective_date || ""}
                            onChange={(e) =>
                              handleEditChange("effective_date", e.target.value)
                            }
                            className="edit-contract"
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            value={editedContractData.expiration_date || ""}
                            onChange={(e) =>
                              handleEditChange(
                                "expiration_date",
                                e.target.value
                              )
                            }
                            className="edit-contract"
                          />
                        </td>
                        <td>
                          <button onClick={() => handleEditSave(contract.id)} className="save_Design">
                            Save
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={{ color: "#B793ED" }}>
                          {contract.contract_name || "N/A"}
                        </td>
                        <td>{contract.contract_owner_name || "N/A"}</td>
                        <td>{contract.customer_name || "N/A"}</td>
                        <td>{contract.effective_date || "N/A"}</td>
                        <td>{contract.expiration_date || "N/A"}</td>
                        <td>
                          <img
                            src="./action.svg"
                            alt="Actions"
                            width="20"
                            height="20"
                            onClick={() => toggleActionMenu(index)}
                            style={{ cursor: "pointer" }}
                          />
                          {isActionMenuOpen === index && (
                            <div className="action-menu">
                              <div
                                className="action-item"
                                onClick={() => handleViewMore(contract.key)}
                              >
                                <img
                                  src="./viewmore.svg"
                                  alt="View More Icon"
                                  className="action-icon"
                                />
                                <p>View More</p>
                              </div>
                              <div
                                className="action-item"
                                onClick={() => enableEdit(contract)}
                              >
                                <img
                                  src="./edit.svg"
                                  alt="Edit Icon"
                                  className="action-icon"
                                />
                                <p>Edit</p>
                              </div>
                              <div
                                className="action-item"
                                onClick={() => handleDelete(contract.id)}
                              >
                                <img
                                  src="./delete.svg"
                                  alt="Delete Icon"
                                  className="action-icon"
                                />
                                <p>Delete</p>
                              </div>
                            </div>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="no-data">
                    No contracts available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination">
  <div className="pagination-dropdown">
    <select
      value={currentPage}
      onChange={handlePageChange}
      className="page-selector"
    >
      {Array.from({ length: totalPages }, (_, index) => (
        <option key={index} value={index + 1}>
          {index + 1}
        </option>
      ))}
    </select>
  </div>
  <p className="contentDown">of {totalPages} pages</p>
  <button><img src="decrease.svg" alt="Previous Page" onClick={handleDecreasePage} style={{ cursor: "pointer" }} /></button>
  <button><img src="increase.svg" alt="Next Page" onClick={handleIncreasePage} style={{ cursor: "pointer" }} /></button>
</div>

        </div>
      </div>

      {popupVisible && (
      <div className="popup-overlay">
        <div className="popup-box">
          <h2>{popupTitle}</h2>
          <p>{popupMessage}</p>
          <button onClick={closePopup} className="popup-close-button">Close</button>
        </div>
      </div>
    )}

{confirmDeleteVisible && (
  <div className="popup-overlay">
    <div className="popup-box-delete">
      <h2>Confirm Deletion</h2>
      <p>Are you sure you want to delete this contract? This action cannot be undone.</p>
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'center',marginTop: '20px',marginLeft: '-100px'}}>
        <button onClick={confirmDelete} className="popup-close-button">Yes, Delete</button>
        <button onClick={() => setConfirmDeleteVisible(false)} className="popup-close-button">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      <UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUploadSuccess={handleUploadSuccess}
        type={'contract'}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={toggleFilterModal}
        token={token}
      />
    </div>
  );
};

export default ContractTable;

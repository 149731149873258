import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import './signup.css'
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { v4 as uuid } from "uuid";
import { FcGoogle } from "react-icons/fc";
import { MdAccountCircle } from "react-icons/md";
import OtpPage from "./OtpSection/OtpPage";
import axios from "axios";
import { useNavigate } from "react-router";
import GoogleSignupButton from "../../components/GoogleSignupButton";
import logo from "../../Images/Logo.png"
import loginImage from '../../Images/loginImage.png'
import errorEmailLogo from '../../Images/emailerror.png'
import { Link } from "react-router-dom";

const SignupPage = () => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
    confirmPassword: "", // Added to keep track of confirmPassword

  });

  // Password validation regular expressions
  const passwordRegex = {
    minLength: /(?=.{8,})/,
    hasUppercase: /(?=.*[A-Z])/,
    hasNumber: /(?=.*\d)/,
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasNumber: false,
  });

  // Modelsection
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState("");

  // const openModal = () => {
  //   setModalEmail(signupDetails.email);
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [showTermsError, setShowTermsError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  // const handleSignupDetailsChange = (event) => {
  //   const { name, value } = event.target;
  //   setSignupDetails((prevSignupDetails) => ({
  //     ...prevSignupDetails,
  //     [name]: value,
  //   }));
  // };

 

  const handleSignupDetailsChange = (event) => {
    const { name, value, type, checked  } = event.target;

    if (type === "checkbox" && name === "terms") {
      setIsTermsAccepted(checked); // Update state for the terms checkbox
      setShowTermsError(false); // Hide terms error when the user checks the box

    }
    else if (name === "password") {
      const newPassword = value;
  
      setPasswordValidation({
        minLength: passwordRegex.minLength.test(newPassword),
        // hasUppercase: passwordRegex.hasUppercase.test(newPassword),
        // hasNumber: passwordRegex.hasNumber.test(newPassword),
      });

      setShowPasswordValidation(false);
    }

    setSignupDetails((prevSignupDetails) => ({
      ...prevSignupDetails,
      [name]: value,
    }));

    // Check if passwords match
    if (name === "password" || name === "confirmPassword") {
      setPasswordMatch(
        name === "password"
          ? value === signupDetails.confirmPassword
          : value === signupDetails.password
      );
      if (name === "confirmPassword") {
        setIsFormSubmitted(false); // Clear the error message when typing in confirm password
      }
    }
    setEmailError("");
    setIsBtnDisabled(false)
    
  };


  // console.log(process.env.REACT_APP_API_URL , 'this is url ')
  const [token, setToken] = useState("");

  const handleSignUp = async () => {
    setIsBtnDisabled(true);
    setIsFormSubmitted(true);
    setShowPasswordValidation(true);

    if (!isTermsAccepted) {
      setShowTermsError(true);
      return;
    }

    if (!passwordMatch || !passwordValidation.minLength) {
      return; 
    }
    setIsLoading(true);

    const payload = {
      email: signupDetails.email,
      password: signupDetails.password,
      first_name: signupDetails.first_name,  
      last_name: signupDetails.last_name,
    };
    console.log(payload);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signup/",
        payload
      );
      console.log("response1111111111", response.data.status);
      
      // console.log("token", response.data.data.token);

      if (response.data.status === 400) {
        setEmailError("Email Already Exist")
        // toast.warn("Email Already Exist", {
        //   autoClose: 2000,
        }
        else if (response.data.status === 200) {
          toast.success("Signup successful!", {
            autoClose: 2000,
          });
          window.localStorage.setItem("token", response.data.data.token);
          window.localStorage.setItem("email", signupDetails.email);
          
          // openModal();
          setToken(response.data.data.token);
          navigate("/complete-account");
        }
      } 
     catch (error) {
      toast.error("Signup failed. Please try again.");
      console.error("Signup error:", error);
    }
    finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    setIsBtnDisabled(false)
  }, []);
  
  // const handleNavigateToLogin = () => {
  //   navigate('/signin');
  // };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   if (token) {
  //     setVerified(true);
  //   }
  // }, []);

  // If verified is true, navigate to "/uploadFile"
  // useEffect(() => {
  //   if (verified) {
  //     navigate("/complete-account");
  //   }
  // }, [verified, navigate]);
  return (
  <div className="bg-white ">
    <span className="main">
    <img 
    className="logo-section"
        src={logo} 
        alt="docuSensa logo"  
    />
    </span>
  
    <div className="flex flex-wrap justify-center items-center">
      <section className= "signup-section w-1/2 flex flex-col justify-center px-8 py-8">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full md:mt-0 sm:max-w-md xl:p-0  bg-white ">
            <div className="md:space-y-6">
            <h2 
              className="font-bold get-started" 
              style={{
                color: '#171717',
                
                fontSize: '32px',
                fontWeight: '700',
                lineHeight: 'normal',
              }}
            >
              Get started Now
            </h2>
            <p
              className="font-bold"
              style={{
                color: '#383838',
                
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: 'normal',
              }}
            >
              Enter your credentials to access your Account
            </p>
              <div className=" w-full">
                <GoogleSignupButton
                  setVerified={setVerified}
                  loginMessage={loginMessage}
                />
              </div>

              <div className="or-style my-6">
                <hr className="flex-grow border-gray-300" />
                <span className="px-2 text-gray-500">or</span>
                <hr className="flex-grow border-gray-300" />
              </div>
          
              <div>
                <label
                  for="email"
                  className="block mb-2"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  value={signupDetails.email}
                  onChange={handleSignupDetailsChange}
                  className="p-6 w-[568px] rounded-2xl"
                  placeholder="enter your Email ID here"
                  required
                />
                {emailError && (
                    <p className="text-sm mt-1 flex items-center">
                      <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> {emailError}
                    </p>
                  )}
              </div>

              {/* Password field */}
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2"
                >
                  Enter Your password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="min 8 chars"
                    value={signupDetails.password}
                    onChange={handleSignupDetailsChange}
                    className="p-6 w-[568px] rounded-2xl"
                    required
                  />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="show-pass absolute  top-5 text-gray-500"
                    >
                      {showPassword ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} /> }
                    </button>
                </div>
                {/* Password validation messages */}
                {showPasswordValidation && signupDetails.password && (

                  // <p className="text-sm mt-1 flex items-center">
                  // <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Password must be at least 8 characters long.
                  // </p>

                  <div>
                    {!passwordValidation.minLength &&
                      <p className="text-sm mt-1 flex items-center">
                        <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Password must be at least 8 characters long.
                      </p>
                      }
                    {/* {!passwordValidation.hasUppercase &&
                      "Password must contain at least one uppercase letter."}
                    {!passwordValidation.hasNumber &&
                      "Password must contain at least one number."} */}
                  </div>
                )}
              </div>

            {/* Confirm Password Field */}
              {/* Confirm Password Field */}
              <div className="mt-4">
                <label htmlFor="confirm-password" className="block mb-2">
                  Confirm Your Password
                  </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={signupDetails.confirmPassword}
                    onChange={handleSignupDetailsChange}
                    placeholder="min 8 chars"
                    className=" p-6 w-[568px] rounded-2xl"
                    required
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="show-pass absolute top-5 text-gray-500"
                  >
                    {showConfirmPassword ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} /> }
                  </button>
                </div>

                {/* <p className="text-sm mt-1 flex items-center">
                      <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Password doesn’t match.
                    </p> */}

                {/* Confirm password validation */}
                {isFormSubmitted && !passwordMatch &&(
                    <p className="text-sm mt-1 flex items-center">
                    <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Password doesn’t match.
                  </p>
                  )}
              </div>

              {/* Terms & Privacy */}
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    name="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    className="checkclass"
                    onChange={handleSignupDetailsChange}
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="terms"
                    className="policy"
                  >
                    I agree to the Terms & Privacy
                  </label>
                </div>
              </div>
              {/* <p className="text-sm mt-1 flex items-center">
                    <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Please agree to the Terms & Privacy to continue.
                  </p> */}
              {showTermsError && (
                  <p className="text-sm mt-1 flex items-center">
                  <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Please agree to the Terms & Privacy to continue.
                </p>
                )}

              {/* {!isTermsAccepted && (
                <div className="text-sm text-red-500 mt-2">
                  You must agree to the Terms & Privacy to sign up.
                </div>
              )} */}

                <button
                disabled={isBtnDisabled}
                  onClick={handleSignUp}
                  // disabled={
                  //   isLoading 
                  //   !passwordValidation.minLength ||
                  //   !passwordValidation.hasUppercase ||
                  //   !passwordValidation.hasNumber ||
                  //   !isTermsAccepted
                  // }
                  className={`signup-button ${
                    isTermsAccepted ? 'isTermAccepted' : 'notAccepted cursor-not-allowed'
                  } ${isBtnDisabled ? 'notAccepted' : 'isTermAccepted'}`}
                >
                  Sign Up
                </button>


              {/* <button
                onClick={handleSignUp}
                disabled={
                  !passwordValidation.minLength ||
                  !passwordValidation.hasUppercase ||
                  !passwordValidation.hasNumber ||
                  !isTermsAccepted
                }
                className={`signup-button text-white ${
                  isTermsAccepted ? 'isTermAccepted' : 'notAccepted cursor-not-allowed'
                } `}
              >
                Sign up
              </button> */}

              
              <p className="login-text text-gray-700 ">
                Have an account?{" "}
                <Link
                  to="/signin"
                  className="login-click  hover:underline"
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Right Section - Promo Content */}
      <section className="image-section w-1/2 flex flex-col justify-center px-8 py-8 text-center">
      <div className="image-text">
        <div className="upper-text text-left">
          <h2 className="">Seamless AI Powered Document Processing</h2>
        </div>
        <div className="lower-text text-left">
          <h2>
            Streamline your workflow with advanced AI-driven<br/> tools designed to simplify document handling.
          </h2>
        </div>
      </div>
        
        <div className="">
        <img src={loginImage} alt="signup promotion" className="signup-img w-1/2 object-cover" />
        </div>
        
      </section>
      {/* {showModal && (
        <OtpPage closeModal={closeModal} email={modalEmail} token={token} />
      )} */
      }
    </div>
    </div>
  );
};

export default SignupPage;

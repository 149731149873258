import React, { useState, useRef, useEffect , useCallback} from "react";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const Chat = () => {
  const [messages, setMessages] = useState([
    {
      id: 0,
      msg: "Hello! How can I help you today?",
      client: false,
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const chatContainerRef = useRef(null);
  const token = localStorage.getItem("token");
  // const [pdfUrl] = useState("https://ducusensastorage.blob.core.windows.net/docusensa/pdfs/124_/Norfolk_Consulting_2_iT9IHL3.pdf")
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const pdfKey = location.state?.pdfKey || localStorage.getItem("pdfKey");

  // useEffect(() => {
  //   if (!pdfKey) {
  //     alert("PDF Key missing. Redirecting...");
  //     return;
  //   }
  //   localStorage.setItem("pdfKey", pdfKey);
 

//   const fetchPdfUrl = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/pdf-url/${pdfKey}/`,
//         {
//           headers: {
//             Authorization: `Token ${token}`,
//           },
//         }
//       );
//       setPdfUrl(response.data.pdf_url);
//     } catch (error) {
//       console.error("Error fetching PDF URL:", error);
//       alert("Failed to load PDF.");
//     }
//   };

//   fetchPdfUrl();
// }, [pdfKey]);

// useEffect(() => {
//   if (!pdfKey) {
//     alert("PDF Key missing. Redirecting...");
//     return;
//   }
//   localStorage.setItem("pdfKey", pdfKey);

//   const fetchPdfUrl = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/pdf-url/${pdfKey}/`,
//         {
//           headers: {
//             Authorization: `Token ${token}`,
//           },
//         }
//       );
//       setPdfUrl(response.data.pdf_url);
//     } catch (error) {
//       console.error("Error fetching PDF URL:", error);
//       alert("Failed to load PDF.");
//     }
//   };

//   fetchPdfUrl();
// }, [pdfKey]);
const fetchContracts = useCallback(async () => {
  if (!token) return;

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/readpdf/pdfs/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log("Fetched contracts:", response.data);
    setFiles(response.data);

    // Set the URL for a specific PDF (for example, the first one in the list)
    if (response.data && response.data.length > 0) {
      setPdfUrl(response.data[0].pdf_url); // Adjust the logic to select the desired PDF
    }
  } catch (error) {
    console.error("Error fetching contracts:", error);
  }
}, [token]);

useEffect(() => {
  fetchContracts(); // Fetch contracts when the component mounts
}, [fetchContracts]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const remainingScroll =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop -
        chatContainerRef.current.clientHeight;

      setShowScrollButton(remainingScroll > 10);
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    scrollToBottom();

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages]);

  const handleSend = async (message = inputMessage.trim()) => {
    if (message) {
      const clientMessageData = {
        id: messages.length,
        msg: message,
        client: true,
      };
      const newMessages = [...messages, clientMessageData];
      setMessages(newMessages);
      setInputMessage("");
      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/qdrant-chat/`,
          {
            client_message: message,
            pdf_key: pdfKey,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const botResponse = response.data;

        const botMessageData = {
          id: newMessages.length,
          msg: botResponse.msg,
          client: false,
        };

        setMessages([...newMessages, botMessageData]);
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
        scrollToBottom();
      }
    }
  };

  const formatResponse = (msg) => {
    let formattedText = msg
    .replace(/\n/g, '<br/>')
    .replace(/\n\n/g, '</p><p>')
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
    .replace(/###(.*?)/g, '$1 $1');
    return `<p>${formattedText}</p>`;
  };

  return (
    <div className="full-container">
      <Header icon="/arrow-back.svg" name="Go to Documents" bell={bell} search={search} />
      <div className="content-container">
        <div className="left-side">
          <div className="message-container" ref={chatContainerRef}>
            {messages.map((message) =>
              message.client ? (
                <div key={message.id} className="w-full flex justify-end mb-2">
                  <span className="user-message-bubble">{message.msg}</span>
                </div>
              ) : (
                <div
                  key={message.id}
                  className="bot-message-container w-full flex items-start mb-4"
                >
                  <div className="bot-icon-container">
                    <img
                      src="/bot-icon.svg"
                      alt="Bot Icon"
                      className="bot-icon h-6 w-6 rounded-full mr-2"
                    />
                  </div>
                  <div className="bot-message-content text-gray-800 text-sm rounded-lg p-4 max-w-[85%]">
                    <div className="bot-message-bubble">
                      <p dangerouslySetInnerHTML={{ __html: formatResponse(message.msg) }} />
                    </div>
                    <div className="reactions flex gap-2 text-gray-500">
                      <img src="/charge.svg" alt="" />
                      <img src="/thums-up.svg" alt="" />
                      <img src="/thums-down.svg" alt="" />
                      <img src="/copy.svg" alt="" />
                    </div>
                  </div>
                </div>
              )
            )}
            {loading && <div className="spinner"></div>}
          </div>

          <div className="dummy-message-boxes">
            <div
              className="dummy-message-box border border-purple-400 rounded-lg p-4 mb-2 flex items-center"
              onClick={() => handleSend("What is the key summary of this document?")}
            >
              <img src="/star-dot.svg" alt="" className="mr-2" />
              <p className="text-sm">What is the key summary of this document?</p>
            </div>
            <div
              className="dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center"
              onClick={() => handleSend("What are the important dates mentioned?")}
            >
              <img src="/star-dot.svg" alt="" className="mr-2" />
              <p className="text-sm">What are the important dates mentioned?</p>
            </div>
          </div>

          <div className="custom-input-container">
            <input
              type="text"
              placeholder="Type your message here..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSend();
              }}
              className="custom-input-field"
            />
            <button className="custom-send-button" onClick={handleSend}>
              <img src="/send.svg" alt="Send" />
            </button>
          </div>
        </div>

        <div className="right-side">
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;

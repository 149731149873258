import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import { FaRegCopy, FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import "./contractChat.css";
import { useLocation } from "react-router-dom";
import SideBarContract from "./SideBarContract";

const ContractChat = () => {
  const [messages, setMessages] = useState([
    {
      id: 0,
      msg: "Hello, How can I help you?",
      client: false,
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const chatContainerRef = useRef(null);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [contractKey, setContractKey] = useState("");
  const [contractUrl, setContractUrl] = useState("")
  
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ckey = params.get("ckey");
    setContractKey(ckey);
  }, [location.search]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(()=>{
    const fetchContract = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/view-contract/${contractKey}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
  
        console.log("API Response for view contract:", response.data);
        if (response.status === 200 && response.data.contract_url) {
          setContractUrl(response.data.contract_url);
        } 
      } catch (error) {
        console.log("Error fetching contract URL:", error);
        
      }
    }
    fetchContract()
  },[contractKey])

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const remainingScroll =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop -
        chatContainerRef.current.clientHeight;

      setShowScrollButton(remainingScroll > 10);
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    scrollToBottom();

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages]);

  const handleSend = async (message = inputMessage.trim()) => {
    if (message) {
      const clientMessageData = {
        id: messages.length,
        msg: message,
        client: true,
      };
      const newMessages = [...messages, clientMessageData];
      setMessages(newMessages);
      setInputMessage("");
      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/contract/qdrant-chat-contract/`,
          {
            client_message: message,
            contract_key: contractKey,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const botResponse = response.data;

        const botMessageData = {
          id: newMessages.length,
          msg: botResponse.msg,
          client: false,
          additionalData: {
            question: botResponse.question,
            chat_questions: botResponse.chat_questions,
            question_timestamp: botResponse.question_timestamp,
            answer_timestamp: botResponse.answer_timestamp,
            Source: botResponse.Source,
            client_message_id: botResponse.client_message_id,
            bot_message_id: botResponse.bot_message_id,
            run_id: botResponse.run_id,
          },
        };

        setMessages([...newMessages, botMessageData]);
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
        scrollToBottom();
      }
    }
  };

  const formatResponse = (msg) => {
    let formattedText = msg
            .replace(/\n/g, '<br/>')
            .replace(/\n\n/g, '</p><p>')
            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
            .replace(/###(.*?)/g, '$1 $1');
            return `<p>${formattedText}</p>`;
  }

  return (
    <>
      <div className="full-container">
      {/* <SideBarContract /> */}
      <div className="custom-header-wrapper">
  <Header 
    icon="/arrow-back.svg" 
    name="Go to Contracts" 
    bell={bell} 
    search={search} 
  />
</div>
        <div className="content-container">
          <div className="left-side">
            <div className="message-container" ref={chatContainerRef}>
              {messages.map((message) =>
                message.client ? (
                  <div key={message.id} className="w-full flex justify-end mb-2">
                    <span className="user-message-bubble">
                      {message.msg}
                    </span>
                  </div>
                ) : (
                  <div
                    key={message.id}
                    className="bot-message-container w-full flex items-start mb-4"
                  >
                    <div className="bot-icon-container">
                      <img
                        src="/bot-icon.svg"
                        alt="Bot Icon"
                        className="bot-icon h-6 w-6 rounded-full mr-2"
                      />
                    </div>
                    <div className="bot-message-content text-gray-800 text-sm rounded-lg p-4 max-w-[85%]">
                      <div className="bot-message-bubble">
                      <p dangerouslySetInnerHTML={{ __html: formatResponse(message.msg) }} />
                      </div>
                      <div className="reactions flex gap-2 text-gray-500">
                        <span className="reaction-icon cursor-pointer">
                          <img src="/charge.svg" alt="" />
                        </span>
                        <span className="reaction-icon cursor-pointer">
                          <img src="/thums-up.svg" alt="" />
                        </span>
                        <span className="reaction-icon cursor-pointer">
                          <img src="/thums-down.svg" alt="" />
                        </span>
                        <span className="reaction-icon cursor-pointer">
                          <img src="/copy.svg" alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
              {loading && (
             
                <div className="spinner"></div>
            
              
              )}
            </div>
            
            {/* Dummy Message Boxes */}
            <div className="dummy-message-boxes">
              <div
                className="dummy-message-box border border-purple-400 rounded-lg p-4 mb-2 flex items-center"
                onClick={() => handleSend("Get a summary of the current status, key metrics, and recent activities for Account")}
              >
                <span className="icon mr-2">
                  <img src="/star-dot.svg" alt="" />
                </span>
                <p className="text-sm">
                  Get a summary of the current status, key metrics, and recent activities for Account
                </p>
              </div>
              <div
                className="dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center"
                onClick={() => handleSend("What are the upcoming renewal dates in this Document")}
              >
                <span className="icon mr-2">
                  <img src="/star-dot.svg" alt="" />
                </span>
                <p className="text-sm text-gray-800">
                  What are the upcoming renewal dates in this Document
                </p>
              </div>
            </div>

            <div className="custom-input-container">
              <input
                type="text"
                placeholder="What are the upcoming renewal dates in this Document"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSend();
                }}
                className="custom-input-field"
              />
              <div className="input-divider"></div>
              <button className="custom-send-button" onClick={handleSend}>
                <img src="/send.svg" alt="" />
              </button>
            </div>
            {showScrollButton && (
              <button
                onClick={scrollToBottom}
                className="scroll-to-bottom fixed bottom-16 right-4 bg-blue-500 text-white rounded-full"
              >
                <BsFillArrowDownCircleFill size={22} />
              </button>
            )}
          </div>

          {/* PDF Viewer with <iframe> */}
          <div className="right-side className='h-full w-full pt-0 pb-0 pl-1 pr-1'">
            <iframe 
              src={contractUrl} 
              title="PDF Viewer"
              className="w-full h-full"
              allow="fullscreen"

            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractChat;

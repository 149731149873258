import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './uploadModal.css';
import { useNavigate } from 'react-router-dom';

const UploadModal = ({ isOpen, onClose,onUploadSuccess, type }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  let progressInterval = null;

  const navigate = useNavigate();
  const [contractKey, setContractKey] = useState(null);

  if (!isOpen) return null;

  const triggerFileInput = () => document.getElementById('fileInput').click();

  const handleViewDetails = () => {
    const pdfFile = file; 

    navigate('/Contract/contract-details', { state: { contractKey, pdfFile } });
  };



  const startProgress = () => {

    progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 90) {
          return prevProgress + 1;
        } else {
          clearInterval(progressInterval);
          return prevProgress;
        }
      });
    }, 100); 
  };



  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsUploading(true);
    setIsUploaded(false);
    setProgress(0);
    startProgress();
    handleFileUpload(selectedFile);
  };


  const handleFileUpload = async (selectedFile) => {
    const token = localStorage.getItem('token');

    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const url = type === 'contract' ? `${process.env.REACT_APP_API_URL}/api/v1/contract/qdrant-upload-contract/` : `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/qdrant-upload-async/`
      const response = await axios.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.min(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              90
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgress(100);
          setTimeout(() => {
            setIsUploaded(true);
            setIsUploading(false);
            setContractKey(response.data.contract_key); 
          }, 1000); 
        }, 500);

      } else {
        alert(response.data.msg || "File upload failed");
        setIsUploading(false);
      }
    } catch (error) {
      console.log("Upload error:", error);
      alert(error.response?.data.msg || "An error occurred during file upload. Please try again.");
      setIsUploading(false);
    }
  };

  const handleClose = () => {
    onClose();
    type === 'contract' ? navigate('/Contract'): navigate('/uploadFile'); 
  };


  return (
    <div className="modal-overlay-details">
      <div className="modal-content-details">
        <button className="close-button" onClick={handleClose}><img src="/cross.svg" alt="" /></button>

        {isUploaded ? (
          <div>
            <div className="success-status">
              <div className="success-icon">
                <img src="/checkmark.svg" alt="Success Icon" className="checkmark-icon" />
              </div>
              <h3 className="success-heading">Contract Uploaded Successfully</h3>
              <p className="success-message">Your contract has been uploaded successfully</p>
              <div className="file-info">
                <span className="file-name-icon"><img src="file-icon.svg" alt="" /></span>
                <span className="file-name">{file ? file.name : "Uploaded_File.pdf"}</span>
              </div>
            </div>
            <p className="extraction-message">
              Data has been extracted from <a href="#" className="extraction-link">{file ? file.name : "Uploaded_File.pdf"}</a>
            </p>
            {type === 'contract' ?
            <button className="view-details-button" onClick={handleViewDetails}>
              View Details
            </button>
            :
            <button className="view-details-button" onClick={handleClose}>
             Done
          </button>}
          </div>
        ) : isUploading ? (
          <div className="Full-Box">
            <h3 className="Heading1">Upload Your Contract</h3>
            <p className="Paragraph1">Upload your contract here and start extracting data</p>
            <div className="upload-box">
              <img src="uploading.svg" alt="" className="uploadingIcon" />
              <p className="status-text">File is being uploaded</p>
              <p className="sub-status-text">Your contract is being uploaded</p>
              <p className="progress-label">Your Progress</p>
              <p className="progress-percentage">{progress}% to complete</p>
              <div className="time-estimate">
                <span className="time-icon">⏱</span> 1min
              </div>
              <div className="progress-bar">

                <div
                  className="progress-bar-fill"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
            <div className="file-info">
              <span className="support">Supported formats: PDF</span>
              <span className="maximum">Maximum Size: 25MB</span>
            </div>
          </div>
        ) : (
          <div className="Full-Box">
            <h3 className="Heading1">Upload Your Contract</h3>
            <p className="Paragraph1">Upload your contract here and start extracting data</p>
            <div className="upload-box" onClick={triggerFileInput}>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf"
              />
              <div className="upload-icon">
                <img src="./uploadBig.svg" alt="Upload Icon" className="upload-big" />
                <img src="./uploadSmall.svg" alt="Overlay Icon" className="upload-small" />
              </div>
              <p className="main-text">Drag and Drop Files Here or <span className="choose-file">Choose File</span></p>
            </div>
            <div className="file-info">
  <div className="support">Supported formats: PDF</div>
  <div className="maximum">Maximum Size: 25MB</div>
</div>

          </div>
        )}
      </div>
    </div>  
  );
};

export default UploadModal;
import React, { useState, useRef } from 'react';
import './filterModal.css';
import axios from 'axios';

const FilterModal = ({ isOpen, onClose, token }) => {
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const [filterType, setFilterType] = useState('');
  const [timeRange, setTimeRange] = useState('thisMonth');

  console.log(token)

  const openDatePicker = (ref) => {
    ref.current && ref.current.showPicker();
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  const applyFilter = async () => {
    if (!filterType || !timeRange) {
      alert("Please select both a filter type and time range.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/filter-contracts/?filter_type=${filterType}&time_range=${timeRange}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,  
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Filtered contracts:", data);
      } else if (response.status === 400) {
        const errorData = await response.json();
        alert(errorData.msg);
      } else if (response.status === 404) {
        alert("No contracts found for the selected filter.");
      } else {
        alert("An error occurred while filtering contracts.");
      }
    } catch (error) {
      console.error("Error while filtering contracts:", error);
      alert("Error while filtering contracts.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <button className="close-button" onClick={onClose}>×</button>
        <h2 className="filter-title">Filter By</h2>

        <div className="custom-select-wrapper">
  <select className="filter-select" value={filterType} onChange={handleFilterTypeChange}>
    <option value="" disabled hidden>Select a filter</option>
    <option value="renewal_date">Renewal Date</option>
    <option value="expiration_date">Expiration Date</option>
  </select>
  <img src="down.svg" alt="dropdown icon" />
</div>


        <h3 className="quick-select-label">Quick select</h3>
        <div className="custom-select-wrapper1">
  <select className="quick-select" value={timeRange} onChange={handleTimeRangeChange}>
    <option value="this_month">This month</option>
    <option value="next_3_months">Next 3 Months</option>
    <option value="this_year">This year</option>
  </select>
  <img src="downround.svg" alt="dropdown icon" />
</div>


        <div className="date-picker-container">
          <div className="date-picker">
            <label>From</label>
            <div
              className="date-input-wrapper"
              onClick={() => openDatePicker(fromDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon" />
              <input
                type="date"
                ref={fromDateRef}
                className="date-input"
                placeholder="Select a date"
              />
            </div>
          </div>
          <div className="date-picker">
            <label>To</label>
            <div
              className="date-input-wrapper"
              onClick={() => openDatePicker(toDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon" />
              <input
                type="date"
                ref={toDateRef}
                className="date-input"
                placeholder="Select a date"
              />
            </div>
          </div>
        </div>

        <button className="apply-filter-button" onClick={applyFilter}>Apply filter</button>
      </div>
    </div>
  );
};

export default FilterModal;

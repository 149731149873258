import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import MainRoutes from "./routes/MainRoutes";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   const crispWebsiteId = process.env.REACT_APP_CRISP_WEBSITE_ID;
  //   if (!crispWebsiteId) {
  //     console.error("Crisp website ID not found.");
  //     return;
  //   }

  //   window.$crisp = [];
  //   window.CRISP_WEBSITE_ID = crispWebsiteId;

  //   (function () {
  //     const d = document;
  //     const s = d.createElement("script");
  //     s.src = "https://client.crisp.chat/l.js";
  //     s.async = 1;
  //     d.getElementsByTagName("head")[0].appendChild(s);
  //   })();
  // }, []);

  return (
    <div className="App w-full h-full">
      <MainRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;

import React, {useEffect, useState} from 'react'
import "./completeAccount.css"
import send from "../../../Images/send.png"
import { useNavigate } from "react-router";
import axios from 'axios';
import { toast } from "react-toastify";
import OtpPage from '../OtpSection/OtpPage';
import verifylogo from "../../../Images/verified.png"
import errorEmailLogo from '../../../Images/emailerror.png'


export const CompleteAccount = () => {

  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState("");
  const [emailAccountVerified, setEmailAccountVerified] = useState(false)
  const [emailVerificationError, setEmailVerificationError] = useState(false)
  const [completeAccountDetails, setCompleteAccountDetails] = useState({
    firstName: "",
    lastName: "",
    billingEmail: "",
    contactNumber: "",
    orgName: "", 
  });
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState('');
    // const [billingEmail, setBillingEmail] = useState("");
    // const [contactNumber, setContactNumber] = useState("");
    // const [orgName, setOrgName] = useState("");
  
    useEffect(()=>{
      const storeEmail = localStorage.getItem('email')
      if(storeEmail){
        setEmail(storeEmail)
      }
    },[])
    

  const openModal = () => {
    setModalEmail(completeAccountDetails.email);
    setShowModal(true);
    setEmailVerificationError("");
  };

  const closeModal = () => {
    setShowModal(false);
  };

    const navigate = useNavigate();

    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   // Handle form submission logic here
    //   console.log({
    //     firstName,
    //     lastName,
    //     billingEmail,
    //     contactNumber,
    //     orgName,
    //   });
    // };h

    const handleValueChange = (e)=>{
      // e.target.value();
      const {name, value} = e.target;
      setCompleteAccountDetails((prevCompleteAccountDetail) => ({
        ...prevCompleteAccountDetail,
        [name]: value,
      }));
      
    }


    const token = localStorage.getItem('token');

    const handleSubmit = async (e)=>{
      e.preventDefault();

      if(!token){
        navigate('/signup');
        return;
      }

      if (!emailAccountVerified) {
        setEmailVerificationError("Your Email is not verified, Please verify your Email ID to Continue");
        return;  
      }

      const payload = {
        first_name: completeAccountDetails.firstName,
        last_name: completeAccountDetails.lastName,
        contact_no: completeAccountDetails.contactNumber,
        alternate_email: completeAccountDetails.billingEmail,
        organization_name: completeAccountDetails.orgName
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/accounts/get-customer-data/`,
            payload,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              "Content-Type": "application/json",
            }
          }
        )
        console.log("complete account detail",response.data);
        if (response.status === 200) {
          console.log("Data submitted successfully");
          toast.success("Data submitted successfully", {
            autoClose: 2000,    
          });
          window.localStorage.setItem("first_name", completeAccountDetails.firstName);
          window.localStorage.setItem("last_name", completeAccountDetails.lastName);
          navigate("/pricing");
        }
      } catch (error) {
        console.log("Customer not found for the logged-in user", error);
        
      }
    }

    // const handleSubmit = (()=>{
    //   navigate("/uploadFile");
    // })
  
    return (
      <div className='account-page'>
      <div className="pageContainer flex flex-col justify-center items-center min-h-screen">
        <h2 className="formTitle mb-6 text-2xl ">Complete Your Account</h2>
        <div className="formContainer bg-white">
          
          <div className="progressBar">
            <div className="progress"></div>
          </div>
          <div className='flex flex-col items-start'>
          <p className="formDescription">Enter Your Personal Details</p>
          <p className="formSubdescription w-[517px]">
            Please provide your details below to complete your account setup.
            These details will be used for communication and billing purposes.
          </p>
          </div>
          
  
          <form className="detailsForm mt-9" 
          onSubmit={handleSubmit}
          >
            <div className="formRow">
              <div className="form-page">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={completeAccountDetails.firstName}
                  onChange={handleValueChange}
                  placeholder="enter here"
                  required
                  className='text-base w-[568px] h-14 rounded-2xl'
                />
              </div>
  
              <div className="form-page">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={completeAccountDetails.lastName}
                  onChange={handleValueChange}
                  placeholder="enter here"
                  required
                  className='text-base w-[568px] h-14 rounded-2xl'
                />
              </div>
            </div>
  
            <div className="formRow">
              <div className="form-page">
                <label htmlFor="email">Email ID</label>
                <div className="emailGroup relative">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    disabled
                    className='text-base w-[568px] h-14 rounded-2xl'
                  />
                   {emailVerificationError && 
                <p className="flex items-center">
                  <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span>{emailVerificationError}
                </p>
                }
                  {emailAccountVerified ? (
                    <img src={verifylogo} alt='verified' className='verified-mark absolute top-6'/>
                  ) : (
                    <button className="verifyBtn absolute top-6" type="button" onClick={openModal}>
                    Verify
                  </button>
                  )}
                  
                </div>
              </div>
  
              <div className="form-page">
                <label htmlFor="billingEmail">Billing Email Address</label>
                <input
                  type="email"
                  name="billingEmail"
                  value={completeAccountDetails.billingEmail}
                  onChange={handleValueChange}
                  placeholder="enter here"
                  required
                  className='text-base w-[568px] h-14 rounded-2xl'
                />
               
              </div>
            </div>
  
            <div className="formRow">
              <div className="form-page">
                <label htmlFor="contactNumber">Contact Number</label>
                <input
                  type="tel"
                  name="contactNumber"
                  value={completeAccountDetails.contactNumber}
                  onChange={handleValueChange}
                  placeholder="enter here"
                  required
                  maxLength={10}
                  className='text-base w-[568px] h-14 rounded-2xl'
                />
              </div>
  
              <div className="form-page">
                <label htmlFor="orgName">Organisation Name</label>
                <input
                  type="text"
                  name="orgName"
                  value={completeAccountDetails.orgName}
                  onChange={handleValueChange}
                  placeholder="enter here"
                  className='text-base w-[568px] h-14 rounded-2xl'
                />
              </div>
            </div>
              <div className='flex justify-end'>
                <button className="continueBtn flex" >
                  Continue
                  <img src={send} alt='send logo' className='send-logo'/>
                </button>
              </div>
            
          </form>
        </div>
        
      </div>
      {showModal && (
        <OtpPage closeModal={closeModal} email={modalEmail} token={token} setEmailAccountVerified={setEmailAccountVerified}/>
      )}
      </div>
    );
}

import React, { useState, useEffect } from 'react'
import uploadlogo from "../../Images/after-upload.png"

export const Documentupload = ({progress}) => {

// const [uploadFile, setUploadFiles] = useState(true);


  return (
    <div>
        <label
            for="dropzone-file"
            className="flex flex-col items-center justify-center cursor-pointer"
        >
            <div className="flex flex-col items-center justify-center pt-4">
            <div className=''>
                <img src={uploadlogo} alt='upload document' className='backup-logo'/>
                {/* <img src={plus} alt='upload document' className='plus-logo absolute bottom-4 right-1'/> */}
            </div>
            <p className="mt-4 text-base font-medium text-[#171717]">
                File is being Uploading 
            </p>
            <p className="text-[#696969] text-[14px] font-medium mt-3">
            Your Contract is being upoladed
            </p>
            <div className='upload-progressLine h-2 w-[795px] rounded-2xl bg-[#D9D9D9] mt-3 mb-3'>
                <div className='active-progressLine  h-2  rounded-2xl bg-[#C95EBE]' style={{ width: `${progress}%` }}></div>
            </div>
            </div>
            {/* <div>
            
            </div> */}
            {/* <input
            onChange={upload}
            onClick={openModal}
            id="dropzone-file"
            type="file"
            className="hidden"
            /> */}
        </label>
    </div>
  )
}

import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoMdContacts } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import "./pricing.css";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import singleUser from '../../Images/single-user.png';
import multiUser from "../../Images/group.png"
import leftArrow from "../../Images/arrow_cool_down.jpg"
import notificaton from "../../Images/notifications.jpg"
import cardCheck from "../../Images/card-check.jpg"

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

  const cardInfo = [
    {
      offer: "limited time Offer",
      title: "Starter",
      planDes: "Free Forever",
      subHeader:
        "Get started with basic document summarization and chat interaction",
      monthlyPrice: 0,
      yearlyPrice: 0,
      button: {
        text: "Get Started",
        icon: <img src={leftArrow} alt="left arrow"/>
      },
      credit: "No Credit Card Required",
      include: "What's Included",
      user: {
        logo: <img src={singleUser} alt="user"/>,
        text: "1 user"
      },
      purchaseText: "Get Started",
      points: [
        "Document Upload: Upload PDFs, DOCX, and TXT files",
        "Summarization: AI-driven document summarization",
        "Pre-Defined Prompts: Access to the Prompt Library",
      ],
    },
    {
      offer: "Recommended",
      title: "Pro",
      planDes: "Ideal for Teams",
      subHeader: "Unlock advanced document management and chat features for teams.",
      monthlyPrice: 12.00,
      yearlyPrice: 120.00,
      button: {
        text: "Buy Now",
        icon: <img src={leftArrow} alt="user"/>
      },
      credit: "No Credit Card Required",
      include: "What's Included",
      user: {
        logo: <img src={multiUser} alt="left arrow"/>,
        text: "Multiple Users"
      },
      purchaseText: "Purchase Pro",
      icon: null, 
      points: [
        "Document Upload: Upload PDFs, DOCX, and TXT files",
        "Summarization: AI-driven document summarization",
        "Pre-Defined Prompts: Access to the Prompt Library",
       
      ],
    },
    {
      offer: "Coming Soon",
      title: "Pro+",
      planDes: "Ideal for Organisations",
      subHeader: "Unlock advanced document management and chat features for teams.",
      monthlyPrice: 99.99,
      yearlyPrice: 299.99,
      button: {
        text: "Get Notified",
        icon: <img src={notificaton} alt="notification"/>
      },
      include: "What's Included",
      user: {
        logo: <img src={multiUser} alt="user"/>,
        text: "Multiple Users"
      },
      purchaseText: "Purchase Pro",
      icon: null,
      points: [
        "Document Upload: Upload PDFs, DOCX, and TXT files",
        "Summarization: AI-driven document summarization",
        "Pre-Defined Prompts: Access to the Prompt Library",
      ],
    },
  ];
  const handlePaymentFrequencyChange = (frequency) => {
    setIsMonthly(frequency);
  };
  // const handlePaymentFrequencyChange = (isMonthly) => {
  //   setIsMonthly(isMonthly);
  // };
  const handleCardClick = (index) => {
    setSelectedCard(index);  // Set the clicked card as the selected one
  };

  const getBackgroundColor = (title) => {
    switch (title) {
      case "Starter":
        return "#ECE0FD"; // Background for Starter plan
      case "Pro":
        return "rgba(201, 94, 190, 0.20)"; // Background for Pro plan
      case "Pro+":
      return "#ECE0FD"; // Background for Pro+ plan
      default:
        return "white"; // Default background
    }
  }
  const getTextColor = (title) =>{
    switch (title) {
      case "Starter":
        return "#8B55DD"; 
      case "Pro":
        return "#C95EBE"; 
      case "Pro+":
      return "linear-gradient(90deg, #8B55DD 0%, #8B55DD 100%)"; // Background for Pro+ plan
      default:
        return "white"; 
    }
  }

  const getButtonColor = (title) =>{
    switch (title) {
      case "Starter":
        return "#8B55DD"; 
      case "Pro":
        return "#8B55DD"; 
      case "Pro+":
      return "#C95EBE"; 
      default:
        return "black"; 
    }
  }

  const getButtonBorderColor = (title) =>{
    switch (title) {
      case "Starter":
        return "#8B55DD"; 
      case "Pro":
        return "#8B55DD"; 
      case "Pro+":
      return "#C95EBE"; 
      default:
        return "black"; 
    }
  }
  // const handleNavigation = () => {
  //   if (cardInfo.title === "Starter") {
  //     navigate("/uploadFile");
  //   }
  // };
  const handleNavigation = () => {
    navigate("/ai-Copilot");
  };

  const getLink = () => {
    return isMonthly
      ? "https://rzp.io/rzp/docusensa-testing" // Monthly Razorpay link
      : "https://pages.razorpay.com/pl_PBk8xMY3wOv18X/view"; // Yearly Razorpay link
  };

  // monthly razor pay :  "https://pages.razorpay.com/pl_PBheiGIKWBSbCt/view"
  // const handleButtonClick = (route) => {
  //   navigate(route);
  // };

  // const getMonthlyRazorPay = () => {
  //   window.location.href = "https://pages.razorpay.com/pl_PBheiGIKWBSbCt/view";
  // }
  
  // const getYearlyRazorPay = () => {
  //   window.location.href = "https://pages.razorpay.com/pl_PBk8xMY3wOv18X/view";
  // }
  

  // const handleButtonClick = (title) => {
  //   if (title === "Pro") {
  //     if (isMonthly) {
  //       getMonthlyRazorPay();  // Redirect to monthly Razorpay link for Pro
  //     } else {
  //       getYearlyRazorPay();  // Redirect to yearly Razorpay link for Pro
  //     }
  //   }
  // };
  // const handlePayment = async () => {
  //   const stripe = await stripePromise;
  //   const paymentFrequency = !isMonthly ? "Monthly" : "Yearly";
  //   // Call your backend to create a Checkout Session
  //   const response = await fetch(
  //     process.env.REACT_APP_BASE_URL + "/api/v1/accounts/create_checkout/",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Token " + localStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({
  //         subscription_plan: "Paid",
  //         payment_frequency: paymentFrequency,
  //       }),
  //     }
  //   );

  //   const session = await response.json();

  //   // Redirect to Stripe Checkout
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: session.session_id,
  //   });

  //   if (result.error) {
  //     console.error(result.error.message);
  //     // Handle errors if needed
  //   }
  // };
  return (
    
      <div className="plan-page bg-white">
        <div className="plan-Container flex flex-col justify-center items-center mt-5">
          <h2 className="top-planheading text-[#BB42AE] text-center font-semibold mb-5 text-2xl ">
            Complete Your Account
            </h2>
            <div>

            
            <div className="progress-Bar">  
              <div className="progress-active"></div>
            </div>
            {/* <div className="flex flex-col justify-start items-start text-left">
              
            </div> */}
            
        
        <div className="flex flex-col items-start mt-5 ">
        <p className="plan-top text-[#18101C] text-base font-semibold">
          Choose the best Plan for You
          </p>
              <p className="plan-subtop mt-3">
                Select a Plan that fits your business needs
              </p>
        </div>

        {/* monthy & yearly sidebar */}
        <div className="flex justify-center mt-3">
          <div className=" monthly-yearly-box flex justify-center items-center w-[331px] h-[64px] rounded-[6px] border border-gray-400 bg-white">
          <h3
            className={`monthly-text mr-3 cursor-pointer ${
              isMonthly ? "active-box monthly-active-box" : ""
            }`}
            onClick={() => handlePaymentFrequencyChange(true)}
          >
            Monthly</h3>
            {/* <label className="inline-flex items-center cursor-pointer"> */}
              {/* <input
                type="checkbox"
                checked={isMonthly}
                onChange={() => handlePaymentFrequencyChange(!isMonthly)}
                className="sr-only peer"
              /> */}
              {/* <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4  peer-focus:ring-purple-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div> */}
              <div
            className={`yearly-text ml-5 cursor-pointer flex items-center ${
              !isMonthly ? "active-box yearly-active-box" : ""
            }`}
            onClick={() => handlePaymentFrequencyChange(false)}
          >
            Yearly <span className="free-text ml-3">2 months Free</span>
          </div>
              
            {/* </label> */}
          </div>
        </div>


        <div className="mid-section w-full h-full py-5">
          <div className="price-card-container w-full h-full flex md:flex-row flex-col items-center justify-center gap-5 pt-4">
            {cardInfo.map((card, index) => (
              <div
                key={index}
                onClick={() => 
                  handleCardClick(index)}
                className={`card border-2 w-[379px] h-[484px] bg-white rounded-xl
                  ${
                    selectedCard === index ? "border-[#C95EBE]" : "border-gray-300"
                  }`}
              >
                <div className="offers flex justify-center items-center mt-2 ml-3"
                style={{
                  backgroundColor: getBackgroundColor(card.title),
                  color: getTextColor(card.title)
                }}
                >
                  {card.offer}
                </div>
                <div className="card-header  text-center ">
                  {card.title}
                </div>
                <div className="plan-des text-center mt-2">
                  {card.planDes}
                </div>
                <div className="card-sub-header text-center mt-3 pl-6 pr-4">
                  {card.subHeader}
                </div>
                <div className="card-price text-center mt-3">
                  {/* Fix the condition here */}
                    {isMonthly ? `$${card.monthlyPrice}` : `$${card.yearlyPrice}`}
                    <sub className="monthly-yearly">
                      {isMonthly ? "/month" : "/year"}
                  </sub>
                </div>
                {/* <div className="flex justify-center items-center mt-4">
                  <button className="card-btn">
                  {card.button}
                  </button>  
                </div> */}
                {/* <div className="credit-text mt-5">
                  {card.credit}
                </div> */}
                <div className="flex justify-center items-center mt-2">
                  <div className="card-processBar"></div>
                </div>
                <div className="mt-3 ml-6 card-include">
                  {card.include}
                </div>
                <div className="card-users mt-3 ml-6"> 
                  {card.user.logo} {card.user.text}
                </div>
                {/* <div className="purchase-container w-full flex justify-center mt-5">
                  <button className="purchase-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center">
                    <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                  </button>
                </div> */}
                {/* <div className="purchase-container w-full flex justify-center mt-5">
                  {index === 1 && ( // Only assign handlePurchaseClick to index 1 (Pro Plan)
                    <button
                      // onClick={handlePayment}
                      className="purchase-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center"
                    >
                      <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                    </button>
                  )}
                  {index === 0 && ( // Render Get Started button for index 0 (Starter Plan)
                    <button
                      // onClick={() => navigate("/uploadFile")}
                      className="get-started-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center"
                    >
                      <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                    </button>
                  )}
                </div> */}
                <div className="card-points-container w-full flex flex-col gap-2 justify-center pt-4">
                  {card.points.map((point, i) => (
                    <div
                      key={i}
                      className="px-6 flex items-center"
                    >
                      <div className="mr-3">
                        {/* <TiTick color="teal" size={25} /> */}
                        <img src={cardCheck} alt="check"/>
                      </div>
                      {point}
                    </div>
                  ))}
                </div>
                <div className="flex justify-center items-center mt-7">
                <button
                    className="card-btn"
                    onClick={
                      card.title === "Starter"
                        ? handleNavigation // Redirect for Starter
                        : () => (window.location.href = getLink()) // Razorpay for others
                    }
                    style={{
                      color: getButtonColor(card.title),
                      border: `1px solid ${getButtonBorderColor(card.title)}`,
                    }}
                  >
                    <a href="#" className="flex gap-2" target="_blank">
                      {card.button.text} {card.button.icon}
                    </a>
                  </button>
                  {/* <button className="card-btn" 
                  onClick={() => handleButtonClick(card.title)}
                  style={{
                  color: getButtonColor(card.title),
                  border: `1px solid ${getButtonBorderColor(card.title)}` 
                }}>
                  {card.button.text}{card.button.icon}
                  </button>   */}
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
        </div>
      </div>
   
  );
};

export default Pricing;
